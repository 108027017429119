
import ThemeMaker from "../../components/thememaker";



const SearchLog = () => {
   
    return (
       
        <ThemeMaker>
        {/* <div className="input-group">
    <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
    <button type="button" className="btn btn-outline-primary">search</button>
  </div> */}
  
        <div className="container">
          <div className="row justify-content-md-center search11">
            <div className="input-group">
              <input
                type="search"
                className="form-control rounded"
                placeholder="Search Log"
                aria-label="Search"
                aria-describedby="search-addon"
              />
            
              <button type="button" className="btn btn-outline-primary button11">
                search
              </button>
              </div>
          </div>
        </div>
      </ThemeMaker>
    )
}

export default SearchLog;

