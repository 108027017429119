import jwt_decode from "jwt-decode";
import { toast } from 'react-toastify';

export const tokenDecode = (tk_access?: any) => {
    try {
        if (tk_access) {
            sessionStorage.setItem("us_st_d", tk_access);
        }
        const token: any = sessionStorage.getItem("us_st_d");
        const decoded: any = jwt_decode(token);
        if (decoded) {
            return decoded;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const errorEmptyAlert = (msg: any) => {
    toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

