/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
// import { tokenDecode } from "../../utils/helper";
// import { config } from "../../utils/config";
import styled from "styled-components"
import BeatLoader from "react-spinners/BeatLoader";
import "./switch.css";
import { show, hide } from "react-functional-modal"
// import Invoice from "../Invoice/Invoice";
// import html2pdf from 'html2pdf.js';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useLocation, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import config from "../../config/config";



// import "./styles.css";
// const { apiEndpoint } = config;
const Pbutton = styled.button
  `
outline: none;
border: none;
margin-left: 18px;
background: #105293;
padding: 3px 5px;

`
const ProviderContainer = styled.div
  `
background: white;
min-width: 60%;
// max-width: 90%;
border: 1px solid rgb(0, 148, 255);
position: relative;
padding: 10px;
border-radius: 20px
`
const ListContainer = styled.div
  `
background: #e3e6f0;
width: 90%;
paddind: 10px;
padding: 10px;
font-size: 14px;
font-weight: 600;
border-radius: 20px
`

const textStyle1 = {
  fontWeight: "600", fontSize: "17px", color: "black"
}


const orderDetail = () => {
  const { baseUrl } = config;

  // let history = useHistory();
  // const location = useLocation();
  // if (location?.state) {
  //   const { order_id }: any = location?.state;
  //   // console.log("object",order_id)
  //   } else {
  //       history.push("/orderlist");
  //       toast.error("Un-Authorized");
  //   }
  let params = useParams();
  const { id: order_id }: any = params;
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
  const [showPop, setShowPop] = useState(false);
  const toggle = (e: any) => {
    e.preventDefault();
    setShowPop(true);
    // useEffect(() => {
    //   // document.body.style.overflowX = "scroll";
    //   document.body.style.height='fit-content';
    //   return () => {
    //     // document.body.style.overflowX = "auto";
    //     document.body.style.height='0';
    //   };
    // }, []);
  };


  const [alldetails, setalldetails] = useState([]);
  // console.log("all details-------------", alldetails);
  const [isLoading, setIsLoading] = useState(false);
  const [updateOrder_, setUpdateOrder_] = useState({
    order_id: "",
    tracking_url: "",
    order_state: "",
  });
  const [orderDetail, setorderDetail]: any = useState([]);
  const [_orderDetail, _setorderDetail]: any = useState({
    billing_address: "",
    contact_number: "",
    created_at: "",
    email: "",
    id: "",
    name: "",
    order_id: "",
    order_state: "",
    provider_id: "",
    shipping_address: "",
    status: "",
    total_price: 0,
    return_amount: 0,
  });
  const [orderState, setOrderState] = useState({
    order_id,
    order_state: "",
    fulfillment_state: "",
    tracking_url: "",
  });

  const [isChecked, setisChecked]: any = useState("");
  const [cancelvalue, setCancelvalue]: any = useState("");
  const [cancelPartialreson, setCancelPartialreson]: any = useState("");
  const [logisticCharges, setlogisticCharges]: any = useState("")
  let rawdata;
  const [orderCancle, setOrderCancle]: any = useState({
    order_id,
    order_status: "Created",
    product_id: [],
  });

  const [addButton, setaddButton] = useState(true);
  // const shipping_address = JSON.parse(_orderDetail?.shipping_address);


  const [orderStatus, setorderStatus] = useState([
    "Created",
    "Accepted",
    "In-progress",
    "Completed",
    // "Cancelled",
    // "Returned",
  ]);

  const steps = [
    'Order Picked Up',
    'In-Transit',
    'Delivered',
  ];

  const getorderDetails = async (order_id: any) => {
    // debugger
    setIsLoading(true);
    try {
      const { data } = await axios({
        url: `${baseUrl}/orders/order_details`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`,
      },
        data: {
          order_id,
        },
      });
      // console.log("order detail ->------   ", JSON.parse(data.data[0]?.quote));
      // console.log("order state ->------   ", data.data[0].order_state);
      if (data.status === 200) {
        setIsLoading(false);
        setorderDetail(data?.data);
        setOrderCancle(data?.data);
        setlogisticCharges(JSON.parse(data.data[0]?.quote))
        setalldetails(data);
        let totalP = 0;
        data.products.forEach((item: any) => {
          totalP += parseFloat(item?.price);
        });
        if (data.data[0].order_state !== "") {
          const index = orderStatus.indexOf(data.data[0].order_state);
          if (index !== -1) {
            setorderStatus(orderStatus.slice(index));
          }
        }

        setOrderState({
          ...orderState,
          order_state: data.data[0]?.order_state,
          tracking_url: data.products[0]?.tracking_url,
          fulfillment_state: data.products[0]?.fulfillment_state,
        });

        setUpdateOrder_({
          ...updateOrder_,
          order_id: data.data[0]?.order_id,
          tracking_url: data.data[0]?.tracking_url,
          order_state: data.data[0]?.order_state,
        });
        _setorderDetail({
          ..._orderDetail,
          ...data.data[0],
          return_amt:
            JSON.parse(data.data[0]?.order_total) - data.data[0].return_amount,
          total_price: JSON.parse(data.data[0].order_total),
          billing_address: JSON.parse(data.data[0]?.billing),
          shipping_address: JSON.parse(data.data[0]?.fulfillments),
        });

        // setOrderCancle({
        //   ...orderCancle,
        //   order_id: order_id,
        //   product_id: [
        //     {
        //       item_id:data.products.product_id,
        //       order_status:orderState.order_state,
        //       qty:orderState.order_state,
        //     }
        //   ]
        // })

        rawdata = data.products.map((item: any, i: any) => ({
          item_id: item.product_id,
          order_status: orderState.order_state,
          qty: cancelvalue,
        }));

        // console.log("line 145", rawdata);
        orderCancle.product_id = Object.assign(rawdata);
        setOrderCancle(orderCancle);
        // console.log("line 164", orderCancle);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const updateOrder = async (product_id: any) => {
    try {
      // console.log(orderDetail);
      const getItem = orderDetail.find(
        (item: any) => item?.product_id === product_id
      );
      // console.log("getItem", getItem);
      const { data } = await axios({
        // url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: getItem,
      });
      // console.log(data, "datadata");

      if (data.message === "flag1") {
        toast.success("Item status updated !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("yess", error);

      // console.log("update error", error);
      // toast.error("404 error !", {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      // });

      // window.location.reload();
    }
  };
  const updateOrderChangeHandler = (e: any, index: any) => {
    // setUpdateOrder_({ ...updateOrder_, [e.target.name]: e.target.value })
    // console.log(e.target.value, "index---", e.target.name, index);
    const newUpdate: any = orderDetail;
    newUpdate[index][e.target.name] = e.target.value;
    // console.log(newUpdate, "newUpdate");

    setorderDetail([...newUpdate]);
  };
  const updateThisOrder = async (e: any) => {
    console.log("orderState---->", orderState);
    try {

      const { data } = await axios({
        // url: `${apiEndpoint}/updateorderstatus`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: orderState,
      });
      console.log("orderState", orderState);

      if (data.status === 200) {

        const index = orderStatus.indexOf(orderState.order_state);
        if (index !== -1) {
          setorderStatus(orderStatus.slice(index));
        }


        if (orderState.order_state === "Created") {
          toast.success("Order Created successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (orderState.order_state === "Accepted") {
          toast.success("Order Accepted successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (orderState.order_state === "In-progress") {
          toast.success("Order In-progress!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (orderState.order_state === "Completed") {
          toast.success("Order Completed successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (orderState.order_state === "Cancelled") {
          toast.success("Order Cancelled successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // toast.success("Order updated !", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      } else {
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong 500 !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const cancleQty = (item: any, setCancelvalue: any) => {
    item.qty = setCancelvalue;
    // setCancelvalue(cancelvalue);
    // console.log("value", item.qty);
  };
  const cancleReason = (item: any, setCancelPartialreson: any) => {
    item.reason = setCancelPartialreson;
    // setCancelPartialreson(cancelPartialreson);
    // console.log("reason", item.reason);
  };
  const cancleChecked = (item: any, setisChecked: any) => {
    item.Checked = setisChecked;
    // setCancelPartialreson(cancelPartialreson);
    // console.log("Checked", item.Checked);
  };
  const [cancelQuantityl, setcancelQuantityl] = useState("")
  const [reasonCode, setreasonCode] = useState("")
  const [cancellingProduct, setcancellingProduct] = useState("")
  const [showGreaterValueAlert, setshowGreaterValueAlert] = useState(false)
  const [showEnterValueAlert, setshowEnterValueAlert] = useState(false)
  const [showCancelQuantityGreaterthenOrderQuantity, setshowCancelQuantityGreaterthenOrderQuantity] = useState(false)
  const cancleUpdate = (
    cancelvalue: any,
    cancelPartialreson: any,
    item: any,
    item_id: any,
    order_status: any
  ) => {

    const newrawdata = [
      {
        item_id: item_id,
        order_status: order_status,
        cancel_quantity: item.qty,
        reason_code: item.reason,
      },
    ];

    orderCancle.product_id = Object.assign({}, newrawdata);
    // console.log("line 297", orderCancle);
  };
  const cancelPartial = async (e: any, item: any) => {

    setcancellingProduct(item.id)
    let payload = {
      "order_id": orderCancle?.order_id,
      "order_status": "Created",
      "product_id": {
        "0": {
          "item_id": item.product_id,
          "order_status": "Accepted",
          "cancel_quantity": cancelQuantityl,
          "reason_code": reasonCode
        }
      }

    }

    console.log(cancelQuantityl, reasonCode)

    if (cancelQuantityl <= item.quantity) {

      if (reasonCode !== "" && cancelQuantityl !== "") {
        // alert()
        try {

          const response = await axios({
            // url: `${apiEndpoint}/sellerordercancel`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
            },
            data: payload,
          });
          if (response.status === 200) {
            setcancellingProduct("");
            getorderDetails(order_id);
            setcancelQuantityl("")
            setreasonCode("")
            toast.success("Order cancel updated !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

          } else {
            // console.log("response for cancelation", response);
            setcancellingProduct("")
            toast.error("Something went wrong !", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } catch (error) {
          console.error(" error 784", error);
          setcancellingProduct("")
          toast.error("Something went wrong !", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        setcancellingProduct("")
        // alert("Please enter cancel quantity and reason for cancel")
        setshowEnterValueAlert(true)
        setTimeout(() => {
          setshowEnterValueAlert(false)
        }, 4000);
      }
    } else {
      setcancellingProduct("")
      setshowCancelQuantityGreaterthenOrderQuantity(true)
      setTimeout(() => {
        setshowCancelQuantityGreaterthenOrderQuantity(false)
      }, 4000);
      // alert("Cancel quantity price cannot be greater than Order Quantity")
    }

  };

  useEffect(() => {
    getorderDetails(order_id);
    window.scrollTo(0, 0)
    // console.log(_orderDetail.order_state, "<-----------_orderDetail.order_state ")
  }, []);

  // if (_orderDetail.order_state !== "") {
  //     const index = orderStatus.indexOf(_orderDetail.order_state);
  //     if (index !== -1) {
  //       setorderStatus(orderStatus.slice(index));
  //     }
  //   }
  // console.log("orderDetails---->", _orderDetail);
  // console.log("orderStatus---->", orderStatus);
  const [cancelpopup, setcancelpopup] = useState(false);
  const [selectedStatus, setselectedStatus] = useState();
  const cancelbtn = () => {
    setcancelpopup(true);
    // console.log("cancelPopup");
  };

  const [cancelreson, setcancelreson] = useState("");
  const cancelReasonStatement = [
    {
      text: " Price of one or more items have",
      code: "001"
    },
    {
      text: "One or more items in the Order not available",
      code: "002"
    },
    {
      text: "Product available at lower than order price",
      code: "003"
    },
    {
      text: "Order in pending shipment / delivery state for too long",
      code: "004"
    },
    {
      text: "Merchant rejected the order",
      code: "005"
    },
    {
      text: "Order not shipped as per buyer app SLA",
      code: "006"
    },
    {
      text: "Order not ready for pickup",
      code: "008"
    },
    {
      text: "Wrong product delivered",
      code: "009"
    },
    {
      text: "Buyer wants to modify details",
      code: "010"
    },
    {
      text: "Buyer not found or cannot be contacted",
      code: "011"
    },
    {
      text: "Buyer does not want product any more",
      code: "012"
    },
    {
      text: "Buyer refused to accept delivery",
      code: "013"
    },
    {
      text: "Address not found",
      code: "014"
    },
    {
      text: "Buyer not available at location",
      code: "015"
    },
    {
      text: "Accident / rain / strike / vehicle issues",
      code: "016"
    },
    {
      text: "Order delivery delayed or not possible",
      code: "017"
    },
    {
      text: "Delivery pin code not serviceable",
      code: "018"
    },
    {
      text: "Pickup pin code not serviceable",
      code: "019"
    },
  ]
  const changeReturnStatus = (selectedStatus: any) => {
    // console.log("selectedStatus", selectedStatus);
    // istatus = selectedStatus;
    setcancelreson(selectedStatus);
  };

  const ordercancel = async () => {
    // console.log("cancel", cancelreson);
    // console.log("orderCancel hit");

    const statusData = {
      order_id: order_id,
      reason_code: cancelreson,
    };
    const id = toast.loading("Please wait...");
    try {
      const response = await axios({
        method: "post",
        url: "https://shikhar-preprod.herokuapp.com/v1/unsolicated_cancel",
        headers: { Authorization: `Bearer ${localStorage.getItem("us_st_d")}` },
        data: statusData,
      })
        .then(function (response) {
          // console.log("response 173", response);
          toast.update(id, {
            render: "Status Updating",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
          setcancelpopup(false);
        })
        .catch(function (error) {
          console.log("error 176", error);
          toast.update(id, {
            render: "something worng happned",
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });
        })
        .finally(function () {
          // console.log("response   // always executed 179");
        });
    } catch (error) {
      console.error(" error 784", error);
    }
  };

  // ---------------------------------- Code functions for logistics Start------------------------------------------------

  const [selectProvider, setselectProvider]: any = useState(null);
  const [providerPayload, setproviderPayload]: any = useState({
    transaction_id: "",
    message_id: ""
  })
  const [providersData, setprovidersData]: any = useState([])
  const [submitData, setSubmitData]: any = useState([])
  const controller = useRef(false)
  interface Item {
    id: number;
    checked: boolean;
  }
  const [issChecked, setIssChecked] = useState<Item[]>([]);
  const [isinitRunning, setisinitRunning] = useState(false);
  const [WeightSection, setWeightSection] = useState(false);

  const [wAndD, setwAndD]: any = useState({
    weight: '',
    weightUnit: "",
    length: '',
    width: '',
    height: '',
    dimensionUnit: "",
    delivery_type: ""
  })



  useEffect(() => {
    if (providerPayload.transaction_id !== "") {
      setTimeout(() => {
        getProviders()
      }, 3000);
    }

  }, [providerPayload])


  function WandChangeHandler(e: any) {
    setwAndD({ ...wAndD, [e.target.name]: e.target.value })
  }

  async function getProvidersPayload() {
    console.log("form data", wAndD)
    let dataTosend = {
      order_id: order_id,
      delivery_type: wAndD.delivery_type,
      weight: {
        unit: wAndD.weightUnit,
        value: parseInt(wAndD.weight)
      },
      dimensions: {
        length: {
          unit: wAndD.dimensionUnit,
          value: parseInt(wAndD.length)
        },
        breadth: {
          unit: wAndD.dimensionUnit,
          value: parseInt(wAndD.width)
        },
        height: {
          unit: wAndD.dimensionUnit,
          value: parseInt(wAndD.height)
        }
      }
    }
    setselectProvider(false)
    try {
      const { data } = await axios({
        url: `https://preprod.xpressbaazaar.com/adapter/logistics/search`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: dataTosend
      })
      // console.log("search--->", data)
      if (data?.data) {
        const { transaction_id, message_id } = data.data.context
        setproviderPayload((prev: any) => {
          return { ...prev, transaction_id: transaction_id, message_id: message_id }
        })


      }
    } catch (err) {
      console.log("err----->", err);
      setselectProvider(null)
      toast.error("Please Try Again! Something Went Wrong", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function getProviders() {
    const { data } = await axios({
      url: `https://preprod.xpressbaazaar.com/adapter/logistics/providers`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
      },
      data: providerPayload
    })

    setTimeout(() => {
      if (data.message == "flag1" && data?.data.length > 0) {
        setprovidersData(data.data)
        setselectProvider(true)
      } else {
        setselectProvider(null)
      }
    }, 3000);
  }

  async function selectLogisticProvider(item: any, itemId: any) {

    setSubmitData(item);
    setIssChecked((prevItems: any) =>
      prevItems.map((item: any) =>
        item.id === itemId ? { ...item, checked: true } : { ...item, checked: false }
      )
    );

  }


  const [isSelectingProviders, setisSelectingProviders] = useState(false);
  const [isConfirmingProviders, setisConfirmingProviders] = useState(false);

  async function submitSelectedProvider() {
    setisSelectingProviders(true)
    const { transaction_id, message_id, bpp_id, bpp_uri, descripter, bpp_provider_id, item, location } = submitData
    const { data } = await axios({
      url: `https://preprod.xpressbaazaar.com/adapter/logistics/init`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
      },
      data: {
        "order_id": order_id,
        "transaction_id": transaction_id,
        "message_id": message_id,
        "bpp_id": bpp_id,
        "bpp_uri": bpp_uri,
        "descripter": descripter,
        "bpp_provider_id": bpp_provider_id,
        "category_id": item.category_id,
        "items_id": item.id,
        "descriptor": item.descriptor.code,
        "fulfillment_id": item.fulfillment_id,
        "location": location == null ? '' : location[0].id

        // "order_id": "2023-05-30-676472",
        // "transaction_id": "b1f11486-6a48-422b-aaf3-e6d98bfbffbf",
        // "message_id": "9569f153-9933-4046-a8f9-347b7fad9e20",
        // "bpp_id": "qaapis.delcaper.com",
        // "bpp_uri": "https://qaapis.delcaper.com/public/ondc",
        // "descripter": "Delcaper",
        // "descriptor": "P2P",
        // "bpp_provider_id": "Delcaper",
        // "category_id": "Same Day Delivery",
        // "items_id": "1",
        // "fulfillment_id": 1

      }
    })

    if (data?.data?.message?.ack?.status == "ACK") {
      setTimeout(() => {
        setprovidersModalOpen(false)
        setisSelectingProviders(false)
        console.log("timer function")
        hide("key#1")
        setisConfirmingProviders(true)
      }, 2000);
    } else {
      setisSelectingProviders(false)
      alert("Init Failed")

    }


  }


  const [isConfirmingProvider, setisConfirmingProvider] = useState(false)
  const [isUpdatingProvider, setisUpdatingProvider] = useState(false)
  const onConfirmHandler = async (cdata: any) => {
    try {
      setisConfirmingProvider(true)
      const { transaction_id, message_id, bpp_id, bpp_uri, descripter, bpp_provider_id, item, bpp_descriptor_name } = submitData

      const { data } = await axios({
        url: "https://preprod.xpressbaazaar.com/adapter/logistics/confirm",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          "transaction_id": transaction_id,
          "message_id": message_id,
          "bpp_id": bpp_id,
          "bpp_uri": bpp_uri,
          "order_id": order_id,
          "bpp_descriptor_name": bpp_descriptor_name
        }
      })

      if (data?.data?.message?.ack?.status == "ACK") {
        setisConfirmingProvider(false)
        toast.success("Provider Confirmed !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // hide("key#2")
      } else {
        setisConfirmingProvider(false)
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      setisConfirmingProvider(false);
      toast.error("Something went wrong !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("error---->", err)
    }
  }
  const updateLSP = async (cdata: any) => {

    try {
      setisUpdatingProvider(true)
      const { transaction_id, message_id, bpp_id, bpp_uri, descripter, bpp_provider_id, item } = submitData

      const { data } = await axios({
        url: "https://preprod.xpressbaazaar.com/adapter/logistics/update",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {

          "transaction_id": transaction_id,
          "message_id": message_id,
          "bpp_id": bpp_id,
          "bpp_uri": bpp_uri,
          "order_id": order_id

          // "order_id": "2023-05-30-676472",
          // "transaction_id": "b1f11486-6a48-422b-aaf3-e6d98bfbffbf",
          // "message_id": "9569f153-9933-4046-a8f9-347b7fad9e20",
          // "bpp_id": "qaapis.delcaper.com",
          // "bpp_uri": "https://qaapis.delcaper.com/public/ondc",
          // "descripter": "Delcaper",
          // "descriptor": "P2P",
          // "bpp_provider_id": "Delcaper",
          // "category_id": "Same Day Delivery",
          // "items_id": "1",
          // "fulfillment_id": 1

        }
      })

      if (data?.data?.message?.ack?.status == "ACK") {
        setisUpdatingProvider(false)
        toast.success("Provider Confirmed !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // hide("key#2")
      } else {
        setisUpdatingProvider(false)
        toast.error("Something went wrong !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      setisUpdatingProvider(false)
      console.log("error---->", err)
    }
  }



  const [shipmentStatusLoading, setshipmentStatusLoading] = useState(false)
  const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
  const [providersModalOpen, setprovidersModalOpen] = useState(false)
  const Sstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
  };
  const PSstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 2,
  };

  const getShipmentStatus = async () => {
    setshipmentStatusLoading(true)
    setTimeout(() => {
      setshipmentModalOpen(true)
      setshipmentStatusLoading(false)
      // statusModal()
    }, 2000);

    try {
      const { data } = await axios({
        method: "POST",
        url: "https://preprod.xpressbaazaar.com/adapter/logistics/status",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("us_st_d")}`,
        },
        data: {
          "buyer_order_id": "2023-07-31-509804"
        }
      })
      console.log("data----->", data)
    } catch (err) {
      console.log("some error--->", err)
    }


  }

  function handleShipementModalClose() {
    setshipmentModalOpen(false)
  }

  // const statusModal = () => {
  //   show(

  //     <ProviderContainer>

  //       <div className="d-flex justify-content-end">
  //         <span style={{ cursor: "pointer" }} onClick={() => {
  //           hide("key#3")
  //         }}><CloseIcon /></span>
  //       </div>
  //       <div style={{ padding: "20px" }}>

  //         <Box sx={{ width: '100%' }}>
  //           <Stepper activeStep={0} alternativeLabel>
  //             {steps.map((label) => (
  //               <Step key={label}>
  //                 <StepLabel >{label}</StepLabel>
  //               </Step>
  //             ))}
  //           </Stepper>
  //         </Box>
  //       </div>
  //     </ProviderContainer>,
  //     {
  //       key: "key#3",
  //       fading: true
  //     }
  //   )

  // }





  // ---------------------------------- Code functions for logistics End------------------------------------------------



  // ----------------------------------------Code functions for Download invoice start-----------------------------------------
//   function PDFGenerator() {
//     const element = document.getElementById('page-content'); // ID of the element containing the page content
//     html2pdf().set({ margin: 0, filename: `Invoice - ${order_id}`, image: { type: 'jpeg', quality: 1.0 } }).from(element).save();
//   }


  // ----------------------------------------Code functions for Download invoice end-----------------------------------------

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });



  console.log("object",orderDetail)
  
  return (
    <>
      <ThemeMaker>        
        <div className="card container mt-3">
          <div className=" m-2 p-2" style={{ fontSize: "15px", fontWeight: "bold", color: "black" }}>Order ID : {order_id}</div>

          {orderDetail &&
            orderDetail.map((item: any, index: any) => {
              return (
                <>
                  <div className="p-1" key={index}>
                    <strong className="text-info" style={{ paddingLeft: "10px" }}>Items Details</strong>
                    <div className="col-12 d-flex mt-2">
                      <div className="col-4">
                        <img src={item.image} alt="image" className="w-75" />
                      </div>
                      <div className="col-8" style={{ fontWeight: "500", fontSize: "14px", color: "black" }}>
                        <p className='mb-0'>Product: {item?.product_name}</p>

                        {item?.category_name ? (
                          <p className='mb-0'>Category : {item?.category_name}</p>
                        ) : (
                          ``
                        )}
                        {item?.weight ? <p className='mb-0'>Size : {item?.weight}</p> : ``}
                        {item?.quantity ? (
                          <p className='mb-0'>Qty : {item?.quantity}</p>
                        ) : (
                          ``
                        )}
                        {item?.cancel_qty ? (
                          <p className='mb-0'>Cancel Qty : {item?.cancel_qty}</p>
                        ) : (
                          ``
                        )}
                        {item?.price ? (
                          <p className='mb-0'>Price : {item?.price}</p>
                        ) : (``)
                        }
                        {item?.reason_code ? (
                          <span className=""><>Reason : </>
                            {item?.reason_code === "001" ? "Price of one or more items have" : false}
                            {item?.reason_code === "002" ? "One or more items in the Order not available" : false}
                            {item?.reason_code === "003" ? "Product available at lower than order price" : false}
                            {item?.reason_code === "004" ? "Order in pending shipment / delivery state for too long" : false}
                            {item?.reason_code === "005" ? "Merchant rejected the order" : false}
                            {item?.reason_code === "006" ? "Order not shipped as per buyer app SLA" : false}
                            {item?.reason_code === "008" ? "Order not ready for pickup" : false}
                            {item?.reason_code === "009" ? "Wrong product delivered" : false}
                            {item?.reason_code === "010" ? "Buyer wants to modify details" : false}
                            {item?.reason_code === "011" ? "Buyer not found or cannot be contacted" : false}
                            {item?.reason_code === "012" ? "Buyer does not want product any more" : false}
                            {item?.reason_code === "013" ? "Buyer refused to accept delivery" : false}
                            {item?.reason_code === "014" ? "Address not found" : false}
                            {item?.reason_code === "015" ? "Buyer not available at location" : false}
                            {item?.reason_code === "016" ? "Accident / rain / strike / vehicle issues" : false}
                            {item?.reason_code === "017" ? "Order delivery delayed or not possible" : false}
                            {item?.reason_code === "018" ? "Delivery pin code not serviceable" : false}
                            {item?.reason_code === "019" ? "Pickup pin code not serviceable" : false}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <hr />
                  </div>
                </>
              );
            })}

          <div className="row d-flex">
            <div className="col-md-6 col-sm-12 mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="col-12 form-label"
              >
                Order Status
              </label>
              <br />
              <select
                className="form-select col-8"
                aria-label="size 3 select example"
                value={orderState?.order_state}
                name="order_state"
                onChange={(e) =>
                  setOrderState({
                    ...orderState,
                    [e.target.name]: e.target.value,
                  })
                }
              >
                {orderStatus.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  )
                })}
                {/* <option value="Created">Created</option>
                  <option value="Accepted">Accepted</option>
                  <option value="In-progress">In-progress</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option> */}
                {/* <option value="Returned">Returned</option> */}
              </select>
            </div>

            <div className="col-md-6 col-sm-12 mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="col-12 form-label"
              >
                fulfillment
              </label>
              <br />
              <select
                className="form-select col-8"
                aria-label="size 3 select example"
                value={orderState?.fulfillment_state}
                name="fulfillment_state"
                onChange={(e) => {
                  setOrderState({
                    ...orderState,
                    [e.target.name]: e.target.value,
                  })
                }
                }
              >
                <option value="">Select Fulfillment Status</option>
                {(orderState?.order_state == "Created" || orderState?.order_state == "Accepted") && <> <option value="Pending">Pending</option> </>}

                {(orderState?.order_state == "In-progress") && <>
                  <option value="Packed">Packed</option>
                  <option value="Order-picked-up">Order-picked-up</option>
                  <option value="Out-for-delivery">Out-for-delivery</option>

                </>}


                {(orderState?.order_state == "Completed") && <>
                  <option value="Order-delivered">Order-delivered</option>
                </>}


                {/* {(orderState?.order_state == "Cancelled") && <>
                  <option value="Cancelled">Cancelled</option>
                </>} */}
              </select>
            </div>
            <div className="col-12 mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="col-12 form-label"
              >
                Tracking URL
              </label>
              <br />
              <input
                className="form-control"
                type="text"
                placeholder=""
                value={orderState?.tracking_url}
                name="tracking_url"
                onChange={(e) =>
                  setOrderState({
                    ...orderState,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>

            {showPop ? (
              <>
                <div
                  onClick={() => setShowPop(false)}
                  className={`cancelpopupoverlay`}
                ></div>
                <div className={`popup `}>
                  <p className="text-right p-2"> <CloseIcon role="button" onClick={() => setShowPop(false)} className="text-dark" />
                  </p>
                  <p className="text-center p-2" style={{ fontSize: "20px", fontWeight: "800", color: "black" }}>Partial Cancellation</p>

                  {orderDetail &&
                    orderDetail.map((item: any, index: any) => {
                      // console.log(item)
                      return (

                        <>
                          <div className="row p-4 mb-2" key={index}>
                            <div className="d-flex PMOrdeList" style={{ gap: "2px" }}>


                              <div style={{ width: "200px", height: "200px" }}>
                                <img src={item?.image} alt="" style={{ width: "100%", height: "100%" }} />
                              </div>

                              <div>
                                <div className="col-12 mb-2">
                                  <span className="allkeys">Product Name:</span>  {item.product_name}
                                </div>
                                <div className="col-md-12 ">
                                  <span className="allkeys">Order Quantity:</span>   {item.quantity}
                                </div>

                                {(item.quantity == item?.cancel_qty) ? <div className="mt-2"> <span className="badge badge-danger">Product Cancelled</span> </div> : <>
                                  <div className="col-md-12" key={index}>
                                    <label className="col-form-label allkeys" >
                                      Cancel Quantity:
                                    </label>
                                    <input
                                      type="number"
                                      style={{
                                        border: "none",
                                        borderBottom: "1px solid #ccc",
                                      }}
                                      name="qty"
                                      min={1}
                                      max={item.quantity}
                                      value={item.qty}
                                      onChange={(e) => {
                                        console.log(e.target.value)
                                        // cancleQty(item, e.target.value);
                                        if (item.cancel_qty === "") {
                                          if ((parseInt(e.target.value) < 1)) {
                                            setshowGreaterValueAlert(true);
                                            setTimeout(() => setshowGreaterValueAlert(false), 3000)
                                            // setcancelQuantityl("");
                                            e.target.value = ""
                                          } else {
                                            setcancelQuantityl(e.target.value)
                                          }

                                        } else {
                                          if ((parseInt(e.target.value) > (parseInt(item.quantity)) - parseInt(item.cancel_qty)) || (parseInt(e.target.value) < 1)) {
                                            // alert("Value is greater");
                                            setshowGreaterValueAlert(true);
                                            setTimeout(() => setshowGreaterValueAlert(false), 3000)
                                            setcancelQuantityl("");
                                            e.target.value = ""
                                          } else {
                                            setcancelQuantityl(e.target.value)
                                          }
                                        }


                                      }}
                                    // onChange={PartialCancelChangeHandler}
                                    />
                                  </div>
                                  <div className="col-md-12" key={index}>
                                    {item?.cancel_qty && (
                                      <span className="allkeys" style={{ color: "red" }}>Cancelled Qty : {item?.cancel_qty}</span>
                                    )}
                                  </div>
                                  <div className="col-md-12" >
                                    <div key={index}>
                                      <label className="col-form-label allkeys">
                                        Cancel Reason:
                                      </label>
                                      <select className="col-md-6 text-left" name="reason" value={item.reason}

                                        onChange={(e) => {
                                          setreasonCode(e.target.value)
                                        }}
                                      >
                                        <option value="001">
                                          Select Cancle Reason
                                        </option>
                                        {
                                          cancelReasonStatement.map(item => (
                                            <option value={item.code}>{item.text}</option>
                                          ))
                                        }
                                      </select>

                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <div className="col-6 justify-content-end position-relative p-2">
                                      <button
                                        className="btn btn-primary align-end"
                                        type="submit"
                                        onClick={(e) => cancelPartial(e, item)}
                                      >
                                        {
                                          cancellingProduct == item.id ? "Cancelling..." : "Cancel"
                                        }

                                      </button>
                                    </div>
                                  </div>
                                </>}

                              </div>
                            </div>
                          </div >
                          <hr className="hr" />
                        </>

                      );
                    })}

                  <div className="col-12 d-flex justify-content-end position-relative p-2">
                    <button
                      className="btn btn-primary align-end"
                      onClick={(e) => setShowPop(false)}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </>
            ) : null}
            <div className="row m-2 w-100 d-flex justify-content-between">
              <div className=" col-md-6 col-sm-12">
                <button
                  className="btn btn-primary"
                  onClick={(e) => updateThisOrder(e)}
                >
                  Update
                </button>
                {/* <button
                  className="btn btn-secondary ml-3"
                  onClick={(e) => updateLSP(e)}
                >
                  Update LSP
                </button> */}
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="col-12 w-100 m-0 p-0 d-flex btnAlign">
                  {/* <button className="btn btn-primary" onClick={updateThisOrder}>
                  Update
                </button> */}

                  <div className="m-2 ">
                    <button
                      onClick={cancelbtn}
                      className="btn btn-outline-danger"
                    >
                      Order Cancel
                    </button>
                  </div>
                  {cancelpopup ? (
                    <>
                      <div onClick={() => setcancelpopup(false)}
                        className={`cancelpopupoverlay`}></div>
                      <div className={`cancelorderpopup p-3 `}>
                        <div className="row">
                          <div className="col-12 ">
                            <select className="btn btn-outline-dark w-100 text-left"
                              value={cancelreson} onChange={(e) =>
                                changeReturnStatus(e.target.value)
                              }>
                              <option value="">
                                Select Cancel Reason
                              </option>
                              <option value="002">
                                One or more items in the Order not available
                              </option>
                              <option value="005">
                                Merchant rejected the order
                              </option>
                              {/* <option value="008">
                                Order not ready for pickup
                              </option> */}
                              {/* <option value="011">
                                Buyer not found or cannot be contacted
                              </option> */}
                              {/* <option value="013">
                                Buyer refused to accept delivery
                              </option> */}
                              {/* <option value="014">Address not found</option> */}
                              {/* <option value="015">
                                Buyer not available at location
                              </option> */}
                              {/* <option value="016">
                                Accident / rain / strike / vehicle issues
                              </option> */}
                              {/* <option value="017">
                                Order delivery delayed or not possible
                              </option> */}
                              <option value="018">
                                Delivery pin code not serviceable
                              </option>
                              <option value="019">
                                Pickup pin code not serviceable
                              </option>
                              <option value="020">
                                Order lost in transit
                              </option>
                            </select>
                            <p className="text-center mt-3 d-none">{`You selected : ${cancelreson}`}</p>
                          </div>
                          <div className="col-12 m-2 p-2 d-flex justify-content-between">
                            <button
                              className="btn btn-outline-dark col-5 col-md-5"
                              onClick={() => setcancelpopup(false)}
                            >
                              Close
                            </button>
                            <button
                              className="btn btn-outline-danger col-5 col-md-5"
                              onClick={() => ordercancel()}
                            >
                              Cancel Order
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  <div className="m-2 ">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        toggle(e);
                      }}
                    >
                      Partial Cancelation
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* ----------------------------------------HTML for Logistics Part Start ----------------------------------------------- */}
          {/* {(decoded?.data?.user?.seller_id == 519 || decoded?.data?.user?.parent_id == 519) ? null : <div className="m-2 p-2">
            <strong className="text-info">Choose Logistics</strong>

            <div className="d-flex align-items-center" style={{ gap: "2px" }}>
              <input type="radio" name="logistics" onChange={() => setWeightSection(false)} style={{ width: "12%", height: "20px" }} /> <label htmlFor=""> Off Network </label>
            </div>
            <div className="d-flex align-items-center" style={{ gap: "2px" }}>
              <input type="radio" name="logistics" onChange={() => setWeightSection(true)} style={{ width: "12%", height: "20px" }} /> <label htmlFor="" className="m-0"> On Network </label>
              {selectProvider ? <Pbutton onClick={() => setprovidersModalOpen(true)} style={{ color: "white" }}>Get Providers</Pbutton> : (selectProvider == false) ? <BeatLoader ></BeatLoader> : null}
            </div>




            {WeightSection ? <div className="mt-3  d-flex dimensionMain" style={{ flexDirection: "column", gap: "10px" }}>
              <strong className="text-info">Weight & Dimensions</strong>
              <div>

                <div className="d-flex justify-content-center align-items-center" style={{ gap: "6px", width: "88%" }}>
                  Total Weight of Products:
                  <input type="number" className="" style={{ width: "60%" }} name="weight" onChange={(e: any) => { WandChangeHandler(e) }} />
                  <select id="" className="m-0" value={wAndD.weightUnit} name="weightUnit" onChange={(e: any) => { WandChangeHandler(e) }}>
                    <option value="">Select Unit</option>
                    <option value="Gram">Gram</option>
                    <option value="Kilogram">Kilogram</option>
                  </select>
                </div>


              </div>
              <div>
                <div className="d-flex justify-content-between mb-3" style={{ gap: "3px", width: "88%" }}>
                  Dimensions:
                  <select name="dimensionUnit" value={wAndD.dimensionUnit} onChange={(e: any) => { WandChangeHandler(e) }}>
                    <option value="">Select Unit</option>
                    <option value="meter">Meter</option>
                    <option value="centimeter">Centimeter</option>
                    <option value="inch">Inch</option>
                  </select>
                </div>


                <div className="d-flex flex-column" style={{ gap: "3px", width: "88%" }}>
                  <div className="d-flex justify-content-between" style={{ gap: "10px" }}>
                    <label htmlFor="">Length</label> <input type="number" name="length" value={wAndD?.length} onChange={(e: any) => { WandChangeHandler(e) }} />
                  </div>
                  <div className="d-flex  justify-content-between" style={{ gap: "10px" }}>
                    <label htmlFor="">Width</label> <input type="number" name="width" value={wAndD?.width} onChange={(e: any) => { WandChangeHandler(e) }} />
                  </div>
                  <div className="d-flex  justify-content-between" style={{ gap: "10px" }}>
                    <label htmlFor="">Height</label> <input type="number" name="height" value={wAndD?.height} onChange={(e: any) => { WandChangeHandler(e) }} />
                  </div>

                  <div className="d-flex justify-content-between mb-3" style={{ gap: "3px", width: "88%" }}>
                    Select Delivery Type:
                    <select name="delivery_type" value={wAndD.delivery_type} onChange={(e: any) => { WandChangeHandler(e) }}>
                      <option value="">Select Delivery Type</option>
                      <option value="Express Delivery">Express Delivery</option>
                      <option value="Standard Delivery">Standard Delivery</option>
                      <option value="Immediate Delivery">Immediate Delivery</option>
                      <option value="Same Day Delivery">Same Day Delivery</option>
                      <option value="Next Day Delivery">Next Day Delivery</option>
                    </select>
                  </div>


                  <div className="d-flex justify-content-center">


                    <Pbutton onClick={getProvidersPayload} disabled={wAndD?.height !== "" ? false : true} style={{ color: "white", cursor: "pointer", marginTop: "10px" }}>Search Providers</Pbutton>

                  </div> </div>


              </div>
            </div> : null}

          </div>
          } */}
          {/* ----------------------------------------HTML for Logistics Part end ----------------------------------------------- */}

          <button className="btn btn-success col-md-3" onClick={getShipmentStatus}>{shipmentStatusLoading ? <CircularProgress style={{ color: "white" }} size={20} /> : "Get Shipment Status"} </button>

          <div className="m-2 p-2">
            <strong className="text-info">Order Details</strong>
            <p className="d-flex justify-content-between">
              Order Number{" "}
              <span style={{ textAlign: "end" }}>{_orderDetail?.order_id}</span>
            </p>
            <p className="d-flex justify-content-between">
              Bill Number{" "}
              <span style={{ textAlign: "end" }}>
                GST{_orderDetail?.order_id.slice(11, 17)}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              Bill Date{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.created_at}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              Customer Name{" "}
              <span style={{ textAlign: "end" }}>{_orderDetail?.name}</span>
            </p>
            <p className="d-flex justify-content-between">
              Delivery Charges
              <span style={{ textAlign: "end" }}>{(logisticCharges?.breakup && logisticCharges.breakup.length > 0) && logisticCharges?.breakup[logisticCharges?.breakup.length - 2]?.price?.value}</span>
            </p>
            <p className="d-flex justify-content-between">
              Packing Charges
              <span style={{ textAlign: "end" }}>{(logisticCharges?.breakup && logisticCharges.breakup.length > 0) && logisticCharges?.breakup[logisticCharges?.breakup.length - 1]?.price?.value}</span>
            </p>
            {/* <hr /> */}
            <p className="d-flex justify-content-between">
              Total Amount{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.total_price}
              </span>
            </p>
            {_orderDetail?.return_amount === 0 ? (
              ``
            ) : (
              <p className="d-flex justify-content-between">
                Cancel Return Amount{" "}
                <span style={{ textAlign: "end" }}>
                  -{_orderDetail?.return_amount}
                </span>
              </p>
            )}

            <hr />
            <p className="d-flex justify-content-between text-info">
              <strong>Grand Total</strong>{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.return_amount
                  ? `${_orderDetail?.return_amt}`
                  : `${_orderDetail?.total_price}`}
                {/* {`${_orderDetail?.order_total} `} */}
              </span>
            </p>
          </div>
          <div className="m-2 p-2">
            <strong className="text-info">Payment Method</strong>
            <p className="d-flex justify-content-between">
              Order Number{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.order_id.slice(11, 17)}
              </span>
            </p>

            <p className="d-flex justify-content-between">
              Bill Date{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.created_at}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              Total Amount{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.order_total}
              </span>
            </p>
          </div>


          <div className="m-2 p-2">
            <strong className="text-info">Shipping Address</strong>
            <p className="d-flex justify-content-between">
              Name{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.name}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              Email{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.email}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              Mobile Number{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.phone}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              Address{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.address?.building}, {_orderDetail?.billing_address?.address?.locality}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              City{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.address?.city}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              State{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.address?.state}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              Country{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.address?.country}
              </span>
            </p>
            <p className="d-flex justify-content-between">
              Pincode{" "}
              <span style={{ textAlign: "end" }}>
                {_orderDetail?.billing_address?.address?.area_code}
              </span>
            </p>
          </div>

          {/* <Link to={`/invoice/${order_id}`}> <Pbutton style={{ marginBottom: "10px" }}>Check Invoice</Pbutton> </Link> */}
          {/* <Pbutton onClick={PDFGenerator} className='my-2' style={{ margin: "0 auto", display: "block", color: "white" }}>Download Invoice</Pbutton>
          <div style={{ display: "none" }}>
            <Invoice orderId={order_id} />
          </div> */}

        </div>

        <>
          <Modal
            open={shipmentModalOpen}
            onClose={handleShipementModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={Sstyle}>

              <div className="d-flex justify-content-end">
                <span style={{ cursor: "pointer" }} onClick={() => {
                  setshipmentModalOpen(false)
                }}><CloseIcon /></span>
              </div>
              <div style={{ padding: "20px" }}>

                <Box sx={{ width: '100%' }}>
                  <Stepper activeStep={0} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel >{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </div>
            </Box>
          </Modal>
        </>

        <>
          <Modal
            open={providersModalOpen}
            onClose={() => setprovidersModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={PSstyle} className="providersModal">

              <ProviderContainer>
                <div className="d-flex flex-column align-items-center" >
                  <p style={{ position: "absolute", top: "5px", right: "10px", color: "black", cursor: "pointer", fontSize: "14px" }} onClick={() => {
                    setprovidersModalOpen(false)
                  }}><CloseIcon style={{ cursor: "pointer" }} /></p>
                  <h3 className="text-center mt-2" style={{ fontSize: "17px", color: "balck", fontWeight: "600" }}>Providers</h3>

                  <div className="w-100 d-flex flex-column align-items-center" style={{ gap: "13px", maxHeight: "320px", overflow: "scroll" }}>
                    {
                      providersData?.map((item: any, index: any) => {
                        if (index >= issChecked.length) {
                          setIssChecked(prevState => [
                            ...prevState,
                            { id: index, checked: false }
                          ]);
                        }

                        return <ListContainer key={index}>
                          <p style={{ color: "black" }}>Name: {item.bpp_descriptor_name}</p>
                          <p style={{ color: "black" }}>Category: {item.item.category_id}</p>
                          <p style={{ color: "black" }}>Item id: {item?.item?.id} </p>
                          <div style={{ color: "black" }} className="d-flex justify-content-between">Price:Rs{item.item.price.value}
                            <section>
                              <label htmlFor="" style={{
                                display: "inline-block",
                                marginLeft: "5px",
                                color: "black",
                              }}>Select</label>
                              <input type="radio" name="choose"
                                id=""
                                onChange={() => { selectLogisticProvider(item, index) }} style={{
                                  appearance: "none", marginRight: "5px",
                                  position: "relative",
                                  top: "2px",
                                  width: "13px",
                                  height: "13px",
                                  borderRadius: "50%",
                                  border: "1px solid blue",
                                  backgroundColor: issChecked[index]?.checked ? "blue" : "transparent",
                                  outline: "none",
                                  marginLeft: "3px"
                                }}
                              />
                            </section> </div>

                        </ListContainer>
                      })
                    }

                  </div>
                </div>
                <button onClick={submitSelectedProvider} className="btn btn-primary my-2 text-light col-md-2" >{isSelectingProviders ? <CircularProgress style={{ color: "white" }} size={20} /> : "Submit"}</button>

              </ProviderContainer>

            </Box>


          </Modal>

        </>
        <>
          <Modal
            open={isConfirmingProviders}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={PSstyle} className="providersModal">

              <ProviderContainer>
                <div className="d-flex flex-column align-items-center " style={{ height: "220px" }} >
                  <p style={{ position: "absolute", top: "5px", right: "10px", color: "black" }} onClick={() => {
                    setisConfirmingProviders(false)
                  }}><CloseIcon style={{ cursor: "pointer" }} /></p>
                  <h3 className="text-center mt-2" style={{ fontSize: "17px", color: "balck", fontWeight: "600" }}>Confirm Provider</h3>

                  <div className="w-100 d-flex flex-column mt-2" style={{ gap: "6px", maxHeight: "250px", overflow: "scroll" }}>

                    <p className="p-0 m-0"> <span style={textStyle1}> Provider Name:</span> <span className="ml-3"> {submitData?.descripter}</span></p>
                    <p className="p-0 m-0"> <span style={textStyle1}>Category: </span> <span className="ml-3">{submitData?.item?.category_id}</span> </p>
                    <p className="p-0 m-0"> <span style={textStyle1}>Price:</span>  <span className="ml-2">Rs. {submitData?.item?.price?.value}</span>  </p>

                  </div>
                  <div className="d-flex" style={{ gap: "13px" }}>
                    <button onClick={() => { onConfirmHandler(submitData) }} className="btn btn-primary col-md-8 mt-4">{isConfirmingProvider ? <CircularProgress style={{ color: "white" }} size={15} /> : "Confirm"}</button>

                    <button onClick={() => { updateLSP(submitData) }} className="btn btn-primary col-md-8  mt-4">{isUpdatingProvider ? <CircularProgress style={{ color: "white" }} size={15} /> : "Update"}</button>
                  </div>

                </div>
              </ProviderContainer>
            </Box>


          </Modal>

        </>


        <Snackbar open={showGreaterValueAlert} autoHideDuration={2000} >
          <Alert severity="error" sx={{ width: '100%' }}>
            Please fill correct quantity
          </Alert>
        </Snackbar>
        <Snackbar open={showEnterValueAlert} autoHideDuration={3000} >
          <Alert severity="error" sx={{ width: '100%' }}>
            Please enter cancel quantity and reason for cancel
          </Alert>
        </Snackbar>
        <Snackbar open={showCancelQuantityGreaterthenOrderQuantity} autoHideDuration={3000} >
          <Alert severity="error" sx={{ width: '100%' }}>
            Cancel Quantity Can't be grater then Order Quantity
          </Alert>
        </Snackbar>




      </ThemeMaker >
    </>
  );
};

export default orderDetail;
