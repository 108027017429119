import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../../config/config";

const ProtectedRoute = ({ children }: any) => {
    const { baseUrl } = config;
    const history = useHistory();
    const location = useLocation();
    const token: any = sessionStorage.getItem("isLogedIn");
    const TokenChecker = async () => {
        try {
            const { data } = await axios({
                url: `${baseUrl}/login/refresh`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            sessionStorage.setItem("isLogedIn", data.refreshToken);
        } catch (error) {
            sessionStorage.removeItem("isLogedIn");
        }
    }
    useEffect(() => {
        TokenChecker();
    }, []);
    const keyV = location.pathname.split("/")[1];
    try {
        const decoded: any = jwt_decode(token);
        const { data } = decoded;
        const { isAdmin, permission: { product, user, order, order_manage, igm, support } } = data;
        if (decoded) {
            if (isAdmin === "true") {
                return children;
            } else {
                switch (keyV) {
                    case "":
                        return children;
                    case "addproduct":
                        return product.write ? children : history.push("/login")
                    case "productlist":
                        return product.read ? children : history.push("/login");
                    case "buyerfee":
                        return product.read ? children : history.push("/login");
                    case "product":
                        return product.update ? children : history.push("/login");
                    case "user":
                        return user.update ? children : history.push("/login")
                    case "adduser":
                        return user.write ? children : history.push("/login");
                    case "userlist":
                        return user.read ? children : history.push("/login");
                    case "orderlist":
                        return order.read ? children : history.push("/login");
                    case "manage_orders":
                        return order_manage.read ? children : history.push("/login");
                    case "cancelreturn":
                        return order_manage.read ? children : history.push("/login");
                    case "issueslist":
                        return igm.read ? children : history.push("/login");
                    case "onissue":
                        return igm.read ? children : history.push("/login");
                    case "supportlist":
                        return support.read ? children : history.push("/login");
                    case "profile":
                        return children;
                    default:
                        return history.push("/login");
                }
            }
        } else {
            switch (keyV) {
                case "forget-password":
                    return children;
                case "reset-password":
                    return children;
                default:
                    return history.push("/login");
            }
        }
    } catch (error) {
        switch (keyV) {
            case "forget-password":
                return children;
            case "reset-password":
                return children;
            default:
                return history.push("/login");
        }
    }
}

export default ProtectedRoute;
