import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import axios from "axios"
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import ThemeMaker from '../../components/thememaker';
import { Link } from 'react-router-dom';
import aws from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import config from "../../config/config";

const s3Config = {
    bucketName: 'bucket-name',
    dirName: 'directory-name',      /* Optional */
    region: 'ap-south-1',
    accessKeyId: 'ABCD12EFGH3IJ4KLMNO5',
    secretAccessKey: 'a12bCde3f4+5GhIjKLm6nOpqr7stuVwxy8ZA9bC0',
    s3Url: 'https:/your-aws-s3-bucket-url/'     /* Optional */
}
// const aws = require('aws-sdk');


export default function Personaldetails(props) {
    const { baseUrl } = config;
    const token = sessionStorage.getItem("isLogedIn");
    const decoded = jwt_decode(token);
    const { data } = decoded;
    let decodedUserdata = data;

    const param = useParams()
    const id = param.id

    const rawData = {
        id: id,
        pan_name: '',
        pan_image: '',
        street: '',
        area_pincode: '',
        state: '',
        longitude: "",
        latitude: "",
        locality: "",
        bulding: "",
        address_proof_type: "",
        address_proof_type: "",
        address_proof_image: "",
        description: "Shikhar Seller App",
        short_description: "Shikhar Seller App"

    }
    const rawErrorData = {
        pan_name: false,
        pan_image: false,
        street: false,
        city: false,
        area_pincode: false,
        state: false,
        longitude: false,
        locality: false,
        bulding: false,
        latitude: false,
        address_proof_type: false,
        address_proof_type: false,
        address_proof_image: false

    }
    const [personalData, setpersonalData] = useState(rawData);
    const [personalDataError, setpersonalDataError] = useState(rawErrorData);


    const renderAfterCalled = useRef(false);
    if (!renderAfterCalled.current) {
        givedta()
    }


    async function givedta() {
        const { data } = await axios({
            url: `${baseUrl}/user/getpersonaldetail`,
            method: "GET",
            headers: {
                Authorization: ` ${token}`
            },
            // data: {
            //     seller_id: id
            // }
        });

        // setpersonalData(data?.data?.[0] ?? null);
        if (data?.data.length !== 0) {
            setpersonalData(data.data[0])
        }
        setpersonalData(data.data[0])
        renderAfterCalled.current = true;
        if (data.status === 200) {
            setpersonalData(data.data[0])
                .current = true;
        }
    }
    const inputHandler = (e) => {
        setpersonalData({ ...personalData, [e.target.name]: e.target.value });
    }
    const errorHandler = (e) => {
        if (e.target.value) {
            setpersonalDataError({ ...personalDataError, [e.target.name]: false });
        } else {
            setpersonalDataError({ ...personalDataError, [e.target.name]: true });
        }
    }

    const onSubmitHandler = async () => {
        const { id,
            pan_name,
            // pan_image,
            street,
            city,
            area_pincode,
            state,
            longitude,
            latitude,
            locality,
            bulding,
            address_proof_type,
            // address_proof_type,
            address_proof_image,
            short_description,
            description
        } = personalData;
        if (pan_name && address_proof_type && address_proof_type && state &&
            city && longitude && locality && bulding && latitude && area_pincode && street) {
            try {
                const { data } = await axios({
                    url: `${baseUrl}/user/personalDetail`,
                    method: "POST",
                    headers: {
                        Authorization: ` ${token}`
                    },
                    data: personalData
                })
                toast.success("Details submitted Successfully", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // if (data.status === 200) {
                //     toast.success("Details submitted Successfully", {
                //         position: "top-right",
                //         autoClose: 2000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //     });
                // }
            } catch (err) {
                console.log("error", err)
            }
        } else {
            toast.error("Please fill all the fields !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const productImageHandler = async (e) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
                secretAccessKey: "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"
            })
            const params = {
                Bucket: "ondc-marketplace",
                Key: `image/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let result = await s3.upload(params).promise();
            const { Location } = result;
            setpersonalData({ ...personalData, [e.target.name]: Location })
        } catch (exception) {
            console.log(exception);
        }
    };


    return (
        <div className="row m-0 w-100 mt-1">
            <div className="col-md-12 col-sm-12">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >
                                    Pan Name
                                    <span className="text-danger" > * </span>
                                    {
                                        personalDataError?.pan_name !== "valid" && personalDataError?.pan_name !== false ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                <input type="text" className="form-control" name="pan_name" placeholder="Enter Pan Name"
                                    value={personalData?.pan_name}
                                    onChange={(e) => inputHandler(e)}
                                    onBlur={(e) => errorHandler(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >
                                    Pan Image
                                    <span className="text-danger" > * </span>
                                    {
                                        personalDataError?.pan_image !== "valid" && personalDataError?.pan_image !== false ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                {personalData?.pan_image && <a href={personalData?.pan_image} target="_blank" style={{ fontSize: "10px", marginLeft: "3px", textDecoration: "underline" }} rel="noreferrer">View Document</a>}
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => productImageHandler(e)}
                                    accept="image/*"
                                    name="pan_image"
                                    style={{ lineHeight: "1" }}
                                    multiple
                                    onBlur={(e) => errorHandler(e)}
                                    id="image"
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >Street<span className="text-danger" >*</span>
                                    {
                                        personalDataError?.street !== "valid" && personalDataError?.street !== false ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                <input type="text" className="form-control" name="street" placeholder="Enter Street"
                                    value={personalData?.street}
                                    onChange={(e) => inputHandler(e)}
                                    onBlur={(e) => errorHandler(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >City<span className="text-danger" >*</span>
                                    {
                                        personalDataError?.city !== "valid" && personalDataError?.city !== false ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                <input type="text" name="city" className="form-control" placeholder="Enter City"
                                    value={personalData?.city}
                                    onChange={(e) => inputHandler(e)}
                                    onBlur={(e) => errorHandler(e)}
                                />
                            </div>
                        </div>


                        <div className="col-md-4">
                            <div className="form-group">
                                <label >Area PIN Code<span className="text-danger" >*</span>
                                    {
                                        personalDataError?.area_pincode !== "valid" && personalDataError?.area_pincode !== false ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                <input type="number" name="area_pincode" className="form-control" placeholder="Enter Area COde"
                                    value={personalData?.area_pincode}
                                    onChange={(e) => inputHandler(e)}
                                    onBlur={(e) => errorHandler(e)}

                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >Locality<span className="text-danger" > * </span>
                                    {
                                        personalDataError?.locality !== "valid" && personalDataError?.area_pincode !== false ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                <input type="text" name="locality" className="form-control" placeholder="Enter Locality"
                                    value={personalData?.locality}
                                    onChange={(e) => inputHandler(e)}
                                    onBlur={(e) => errorHandler(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >Bulding<span className="text-danger" > * </span>
                                    {
                                        personalDataError?.bulding !== "valid" && personalDataError?.area_pincode !== false ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                <input type="text" name="bulding" className="form-control" placeholder="Enter Locality"
                                    value={personalData?.bulding}
                                    onChange={(e) => inputHandler(e)}
                                    onBlur={(e) => errorHandler(e)}
                                />
                            </div>
                        </div>
                        {/* <div className="col-md-4">
                            <div className="form-group">
                                <label >Area PIN Code<span className="text-danger" >*</span>
                                    {
                                        personalDataError?.area_pincode !== "valid" && personalDataError?.area_pincode !== false ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                <input type="number" name="area_pincode" className="form-control" placeholder="Enter Area COde"
                                    value={personalData?.area_pincode}
                                    onChange={(e) => inputHandler(e)}
                                    onBlur={(e) => errorHandler(e)}

                                />
                            </div>
                        </div> */}
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >State<span className="text-danger" >*</span>
                                    {
                                        personalDataError?.state !== "valid" && personalDataError?.state !== false ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                <select id="State"
                                    className="form-control" name="state" placeholder="State"
                                    value={personalData?.state}
                                    onChange={(e) => inputHandler(e)}
                                    onBlur={(e) => errorHandler(e)}
                                >
                                    <option value="">Select State</option>
                                    <option value="AP">Andhra Pradesh</option>
                                    <option value="AR">Arunachal Pradesh</option>
                                    <option value="AN">Andaman and Nicobar Islands</option>
                                    <option value="AS">Assam</option>
                                    <option value="BR">Bihar</option>
                                    <option value="CG">Chhattisgarh</option>
                                    <option value="CH">Chandigarh</option>
                                    <option value="DH">Dadra and Nagar Haveli</option>
                                    <option value="DD">Daman and Diu</option>
                                    <option value="DL">Delhi</option>
                                    <option value="GA">Goa</option>
                                    <option value="GJ">Gujarat</option>
                                    <option value="HR">Haryana</option>
                                    <option value="HP">Himachal Pradesh</option>
                                    <option value="JK">Jammu and Kashmir</option>
                                    <option value="JH">Jharkhand</option>
                                    <option value="KA">Karnataka</option>
                                    <option value="KL">Kerala</option>
                                    <option value="LD">Lakshadweep</option>
                                    <option value="MP">Madhya Pradesh</option>
                                    <option value="MH">Maharashtra</option>
                                    <option value="MN">Manipur</option>
                                    <option value="ML">Meghalaya</option>
                                    <option value="MZ">Mizoram</option>
                                    <option value="NL">Nagaland</option>
                                    <option value="OR">Orissa</option>
                                    <option value="PB">Punjab</option>
                                    <option value="PY">Pondicherry</option>
                                    <option value="RJ">Rajasthan</option>
                                    <option value="SK">Sikkim</option>
                                    <option value="TN">Tamil Nadu</option>
                                    <option value="TS">Telangana</option>
                                    <option value="TR">Tripura</option>
                                    <option value="UK">Uttarakhand</option>
                                    <option value="UP">Uttar Pradesh</option>
                                    <option value="WB">West Bengal</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >Latitude <span className="text-danger" >*</span>
                                    {
                                        personalDataError?.latitude !== "valid" && personalDataError?.latitude !== false ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                <input type="text" className="form-control" name="latitude" placeholder="Enter Latitude"
                                    value={personalData?.latitude}
                                    onChange={(e) => inputHandler(e)}
                                    onBlur={(e) => errorHandler(e)}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >Longitude <span className="text-danger" >*</span>
                                    {
                                        personalDataError?.longitude !== "valid" && personalDataError?.longitude !== false ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                <input type="text" className="form-control" name="longitude" placeholder="Enter Longitude"
                                    value={personalData?.longitude}
                                    onChange={(e) => inputHandler(e)}
                                    onBlur={(e) => errorHandler(e)}
                                />
                            </div>
                        </div>
                        {/*  */}
                        {/* <div className="col-md-4">
                                <div className="form-group">
                                    <label >Personal Address <span className="text-danger" >*</span>
                                        {
                                            personalDataError?.address_proof_type !== "valid" && personalDataError?.address_proof_type !== false ? (
                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                            ) : null
                                        }
                                    </label>
                                    <input type="text" className="form-control" name="address_proof_type" placeholder="Enter Personal Address"
                                        value={personalData.address_proof_type}
                                        onChange={(e) => inputHandler(e)}
                                        onBlur={(e) => errorHandler(e)}
                                    />
                                </div>
                            </div> */}
                        {/*  */}
                        <div className="col-md-4" style={{ display: "none" }}>
                            <div className="form-group">
                                <label >Short Description of Shop

                                </label>
                                <input type="text" className="form-control" name="short_description" placeholder="Enter Short Description"
                                    value={personalData?.short_description}
                                    onChange={(e) => inputHandler(e)}

                                />
                            </div>
                        </div>
                        <div className="col-md-4" style={{ display: "none" }}>
                            <div className="form-group">
                                <label >Long Description of Shop

                                </label>
                                <input type="text" className="form-control" name="description" placeholder="Enter Long Description"
                                    value={personalData?.description}
                                    onChange={(e) => inputHandler(e)}

                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label >Address Proof Type <span className="text-danger" >*</span>
                                {
                                    personalDataError?.address_proof_type ? (
                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                    ) : null
                                }
                            </label>
                            <div className="form-group">

                                <select name="address_proof_type" id="" className="form-control"
                                    value={personalData?.address_proof_type}
                                    onChange={(e) => inputHandler(e)}
                                    onBlur={(e) => errorHandler(e)}
                                >
                                    <option value="">Select address proof</option>
                                    <option value="Aadhar card">Aadhar card</option>
                                    <option value="Ration card">Ration card</option>
                                    <option value="Telephone bill">Telephone bill</option>
                                    <option value="Bank Passbook">Bank Passbook</option>
                                    <option value="Electricity bill">Electricity bill</option>
                                    <option value="Lease or license agreement">Lease or license agreement</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >Address Proof Image <span className="text-danger" >*</span>
                                    {
                                        personalDataError?.address_proof_image ? (
                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                        ) : null
                                    }
                                </label>
                                {personalData?.address_proof_image && <a href={personalData?.address_proof_image} target="_blank" style={{ fontSize: "10px", marginLeft: "3px", textDecoration: "underline" }} rel="noreferrer">View Document</a>}
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => productImageHandler(e)}
                                    accept="image/*"
                                    name="address_proof_image"
                                    multiple
                                    style={{ lineHeight: "1" }}
                                    onBlur={(e) => errorHandler(e)}
                                    id="address_proof_image"
                                />
                            </div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-success" onClick={() => {
                                onSubmitHandler()
                                setTimeout(() => {
                                    props.pageChangeController()
                                }, 3000)
                            }}>
                                Update
                            </button>
                        </div>
                        {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                    </div>

                </div>
            </div>
        </div>

    )
}
