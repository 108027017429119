/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, CSSProperties } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import '../../components/table.css';
import styles from "./igm.module.css";
import style from "../../components/Search&Options/Search&Options.module.css";
import CircularProgress from '@mui/material/CircularProgress';
import { Paginated } from "../../components/Paginated";
import jwt_decode from "jwt-decode";
import { event } from "jquery";
import config from "../../config/config";
const $ = require('jquery');

const CustomStyles = {
    Status: {
        display: 'grid',
        justifyItems: "stretch",
        alignItems: "center",
    } as CSSProperties,
}

const issueslist = () => {
    const { baseUrl } = config;
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const [issuesList, setIssuesList] = useState([]);
    const [SearchList, setSearchList] = useState([]);
    // const [stransactionList, setStransactionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { data } = decoded;
    let sellerProviderId = data?.seller_data?.provider_id;
    const rawData = {
        seller_id: ''
    }

    const [searchData, setSearchData]: any = useState(rawData);
    const { permission: { category, order, product, role, seller, tax, user } } = data;
    const [sellerList, setSellerList] = useState([]);
    const [valueid, setValueid] = useState("");
    const [valueamount, setValueamount] = useState("");
    const [valuedate, setValuedate] = useState("");

    var count = 1;
    // const shipping_address = JSON.parse(_orderDetail?.shipping_address);
    
    const fetchIssuesList = async () => {
        setIsLoading(true);
        try {
            const {
                data: { data },
            } = await axios({
                url: `${baseUrl}/ondc/getAllIssue`,
                method: "GET",
                headers: {
                    Authorization: ` ${token}`
                },
            });
            if (data && data.length > 0) {
                data.sort((a: any, b: any) => {
                    const dateA: any = new Date(a.createdAt);
                    const dateB: any = new Date(b.createdAt);
                    return dateB - dateA;
                });
                setIssuesList(data);
                setSearchList(data);
            } else {
                setIssuesList([]);
                setSearchList([]);
            }
            // setSearchList(data.filter((item: any) => item.order_details.provider_id === sellerProviderId));
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
        }
    }

    const filterBySearch = (event: any) => {
        const querySearch = event.target.value;
        let SearchFiltere = issuesList.filter((item: any) => {
            return (
                item?.issue_id.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                item?.status?.toLowerCase()?.includes(querySearch?.toLowerCase())
            );
        });
        setSearchList(SearchFiltere);
    };

    // Fields Mapping, Category to Sub-Category
    const subCategoryMap: any = {
        /* ITEM */
        ITM01: "Missing items",
        ITM02: "Quantity Issue",
        ITM03: "Item mismatch",
        ITM04: "Quality issue",
        ITM05: "Expired item",
        /* FULFILLMENT */
        FLM01: "Wrong delivery address",
        FLM02: "Delay in delivery",
        FLM03: "Delayed delivery",
        FLM04: "Packaging",
        FLM05: "Buyer not found",
        FLM06: "Seller not found",
        FLM07: "Package info mismatch",
        FLM08: "Incorrectly marked as delivered",
        /* ORDER */
        ORD01: "Order not received",
        ORD02: "Quality issue",
        ORD03: "Delayed delivery",
        ODR04: "Invoice missing",
        /* AGENT */
        AGT01: "Invoice missing",
        AGT02: "Buyer behavioral issue",
        /* PAYMENT */
        PMT01: "Refund not received",
        PMT02: "Underpaid",
        PMT03: "Overpaid",
        PMT04: "Not paid",
    };
    const COLUMNS = [
        {
            Header: "Sn No",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Ticket No",
            accessor: (item: any) => (item?.issue_id),
            sticky: "left"
        },
        {
            Header: "Issues Type",
            accessor: (item: any) => (item?.issue_type),
            sticky: "left"
        },
        {
            Header: "Category",
            accessor: (item: any) => (item?.category),
            sticky: "left"
        },
        {
            Header: "Sub Category",
            accessor: (item: any) => subCategoryMap[item?.sub_category] || "",
            sticky: "left"
        },
        {
            Header: "Status",
            sticky: "left",
            accessor: (item: any) => {
                const statusMapping: any = {
                    "Closed": "badge-danger",
                    "CLOSED": "badge-danger",
                    "RESOLVED": "badge-success",
                    "PROCESSING": "badge-warning",
                    "NEED-MORE-INFO": "badge-warning",
                };
                const statusClass = statusMapping[item?.status] || "badge-warning";
                return <span style={CustomStyles?.Status} className={`badge ${statusClass}`}>{item?.status}</span>;
            },
        },
        {
            Header: "Action",
            filterable: true,
            sticky: "left",
            Cell: ({ row }: any) => (<Link to={{ pathname: '/onissue', state: { tkt: row?.original.issue_id } }} style={CustomStyles?.Status} className="badge badge-outline-dark btn btn-outline-primary">View</Link>),
        },
    ];
    const getSellerList = async () => {
        try {
            const { data } = await axios({
                // url: `${apiEndpoint}/getsellerlist`,
                method: "GET",
                headers: {
                    Authorization: ` ${token}`
                },
            });
            if (data.status === 200) {
                // console.log(data.data)
                setSellerList(data.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const inputHandler = async (e: any) => {
        setValueid("");
        setValueamount("");
        setValuedate("");

        var optionvalue = e.target.value;
        if (optionvalue) {
            try {
                const {
                    seller_id
                } = searchData;
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    // url: `${apiEndpoint}/filtertransactiondataall`,
                    method: "POST",
                    headers: {
                        Authorization: ` ${token}`
                    },
                    data: {
                        seller_id: optionvalue
                    }

                });
                if (data.status === 200) {
                    // console.log(data.response);
                    // setIssuesList(data.response);
                    // setStransactionList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        }
        else {
            // setIssuesList(stransactionList);
        }
    };

    const inputeHandler = async (e: any) => {
        const optionvalue = e.target.value;
        setValueid(optionvalue);
        if (optionvalue) {
            try {
                const {
                    seller_id
                } = searchData;
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    // url: `${apiEndpoint}/filtertransactionsellerdata`,
                    method: "POST",
                    headers: {
                        Authorization: ` ${token}`
                    },
                    data: {
                        seller_id: { searchData },
                        order_id: optionvalue
                    }
                });
                if (data.status === 200) {
                    // console.log(data);
                    // setIssuesList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };

    const inputeeHandler = async (e: any) => {
        const optionvalue = e.target.value;
        setValueid(optionvalue);
        if (optionvalue) {
            try {

                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    // url: `${apiEndpoint}/filtertransactionsellerlogin`,
                    method: "POST",
                    headers: {
                        Authorization: ` ${token}`
                    },
                    data: {
                        order_id: optionvalue
                    }

                });

                if (data.status === 200) {
                    // console.log(data.response);
                    // setIssuesList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchIssuesList();
        }
    };

    const inputaHandler = async (e: any) => {
        const optionvalue = e.target.value;
        setValueamount(optionvalue);
        if (optionvalue) {
            try {
                const {
                    seller_id
                } = searchData;
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    // url: `${apiEndpoint}/filtertransactionsellerdata`,
                    method: "POST",
                    headers: {
                        Authorization: ` ${token}`
                    },
                    data: {
                        seller_id: { searchData },
                        order_total: optionvalue
                    }
                });
                if (data.status === 200) {
                    // console.log(data);
                    // setIssuesList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            inputHandler(e);
        }
    };

    const inputaaHandler = async (e: any) => {
        const optionvalue = e.target.value;
        setValueamount(optionvalue);
        if (optionvalue) {
            try {
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                const { data } = await axios({
                    // url: `${apiEndpoint}/filtertransactionsellerlogin`,
                    method: "POST",
                    headers: {
                        Authorization: ` ${token}`
                    },
                    data: {
                        order_total: optionvalue
                    }
                });
                if (data.status === 200) {
                    // console.log(data.response);
                    // setIssuesList(data.response);
                }
            } catch (error) {
                console.log("error", error);
            }
        } else {
            fetchIssuesList();
        }
    };

    useEffect(() => {
        fetchIssuesList();
        // getSellerList();
    }, []);

    // console.log("SearchList", SearchList);

    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-danger text-left">IGM Dashboard</h6>
                                {seller?.read ?
                                    <div className={`${styles.sellerListOptions}`}><b>Filter By Seller: </b> <div className={styles.selectionControl}>
                                        <select name="seller_id" id="" onChange={(e: any) => inputHandler(e)} >
                                            <option value="">Select Seller</option>
                                            {
                                                sellerList.map((item: any) => (
                                                    <option value={item?.seller_id} key={item?.seller_id}>{item?.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    </div>
                                    : ""}
                                <div className="search mt-1">
                                    <div className="row m-0 d-flex justify-content-center">
                                        <div className="d-flex w-100">
                                            <div className={style.inputMain}>
                                                <label htmlFor="exampleInputEmail1">Search By Ticket ID | Ticket Status</label>
                                                <input type="search" name="order_id" placeholder="Search" className="mr-2 form-control" onChange={(event) => filterBySearch(event)} />
                                            </div>
                                            {/* <div className={style.inputMain}> <input type="search" name="amount" placeholder="Search by Amount" className="mr-2" onChange={(e) => { seller?.read ? inputaHandler(e) : inputaaHandler(e) }} value={valueamount ? valueamount : ""} />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0" style={{ padding: "10px !important" }}>
                                <div className="table-responsive table-bordered ">
                                    {/* .slice().reverse() */}
                                    {!isLoading ? <Paginated data={SearchList} columns={COLUMNS} /> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>}
                                    {/* <div className={styles.transactionListing_desktop}>
                                        <Paginated data={transactionList} columns={COLUMNS} />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default issueslist;