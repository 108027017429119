/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect, useCallback } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ThemeMaker from "../../components/thememaker";
import Styles from "./style.module.css";
import { toast } from "react-toastify";
import { Modal, Box, CircularProgress, TableContainer, Paper, Table, TableHead, TableRow, TableCell } from '@mui/material';
import axios from "axios";
import { Paginated } from "../../components/Paginated";
import "../../index.css";
import moment from "moment";
import config from "../../config/config";
import jwt_decode from "jwt-decode";


const Settled = () => {
  const { baseUrl } = config;
  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);
  const { data: any } = decoded;
  const [toggleState, setToggleState] = useState(1);
  const [details, setDetails]: any = useState([]);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [transactionDetails, setTransactionDetails]: any = useState([]);
  const [manageFilter, setManageFilter]: any = useState([]);
  // const [settlementAmount, setSettlementAmount]: any = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(true);
  const modelOpen = () => setOpen(true);
  const [tdsValue, settdsValue] = useState(0);
  const [tcsValue, settcsValue] = useState(0);
  const [settleClicked, setsettleClicked] = useState(false);
  const [Transactionid, setTransactionid]: any = useState("")
  const [TransactionidDate, setTransactionidDate]: any = useState("")

  const handleClose = () => {
    setOpen(false);
    setsettleClicked(false);
  };
  const toggleTab = (e: any) => {
    setToggleState(e);
  };

  const Mstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  // 
  const handleStartDateChange = (event: any) => {
    setStartDate(event.target.value);
    // console.log("event.target.value", event.target.value)
  };

  const handleEndDateChange = (event: any) => {
    setEndDate(event.target.value);
    // console.log("event.target.value", event.target.value)
  };

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month: string | number = today.getMonth() + 1;
    let day: string | number = today.getDate();

    // Add leading zero if month or day is a single digit
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

  const getTransactionbyId = async (order_id: any) => {
    try {
      setloading(true)
      const { data } = await axios({
        url: `${baseUrl}/v1/filterdata`,
        method: "POST",
        headers: {
          Authorization: token,
        },
        data: {
          order_id
        }
      });
      setDetails(data)
      setloading(false)
    } catch (error) {
      console.log("Something Went Wrong!", error)
      setloading(false)
    }
  }

  const totalSettlementAmount = async () => {
    try {
      setloading(true)
      const { data } = await axios({
        url: `${baseUrl}/v1/get_unsettlement_status`,
        method: "POST",
        headers: {
          Authorization: token,
        },
        data: {
          start_date: startDate,
          end_date: endDate
        }
      });
      const hasData = data && data?.length > 0;
      setTransactionDetails(hasData ? [...(data || [])] : []);
      setManageFilter(hasData ? [...(data || [])] : []);
      setloading(false)
    } catch (error) {
      console.log("Something Went Wrong!", error)
      setloading(false)
    }
  };

  function calculateGST(originalValue: any, gstRate: number) {
    let z = parseInt(originalValue)
    return (z * gstRate) / 100;
  }
  const Save_Settled = async () => {
    try {
      setloading(true)
      const { data } = await axios({
        url: `${baseUrl}/v1/trans_filter`,
        method: "POST",
        headers: {
          Authorization: token,
        },
        data: {
          start_date: startDate,
          end_date: endDate
        }
      });
      const hasData = data?.response && data?.response?.length > 0;
      // setTransactionDetails(hasData ? [...(data?.response || [])] : []);
      // setManageFilter(hasData ? [...(data?.response || [])] : []);
      setloading(false)
    } catch (error) {
      console.log("Something Went Wrong!", error)
      setloading(false)
    }
  };

  async function receiveHandler() {
    try {
      const { data } = await axios({
        url: `${baseUrl}/v1/updatesettlement_status`,
        headers: {
          Authorization: token,
        },
        method: "POST",
        data: {
          order_id: details[0]?.order_id,
          tds: tdsValue,
          tcs: tcsValue,
        },
      })

      if (data.status === 200) {
        toast.success("Received Successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.error("Something Went Wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }

  }
  async function submitCheckedDataSingle() {
    try {
      const { data } = await axios({
        url: `${baseUrl}/v1/updatesettlement_status`,
        headers: {
          Authorization: token,
        },
        method: "POST",
        data: {
          order_id: details[0]?.order_id,
          transaction_id: Transactionid,
          settlement_status: 1,
          settlement_date: TransactionidDate
        },
      })

      if (data.status == 200) {
        if (data.status === 200) {
          toast.success("Settled Successfully!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // clearSelectionHandler()
        }
      } else {
        toast.error("Error in Setteling!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.error("Something Went Wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    }

  }
  // FILTER

  const filterBySearch = (event: any) => {
    const querySearch = event.target.value;
    let SearchFiltere = transactionDetails.filter((item: any) => {
      return (
        item?.order_id.toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.seller_amount?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.seller_name?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.ondc_gateway_amount?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.settlement_status?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.utr?.toString().toLowerCase().includes(querySearch.toLowerCase())
      );
    });
    setManageFilter(SearchFiltere);
  };
  const preventMinus = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };
  // const exportToCSV = (orderList: any) => {
  //   const headers = [
  //     'S.No',
  //     'Company Name	',
  //     'Order Id',
  //     'Receive Amount',
  //     'Order Total',
  //     'Settlement Status',
  //     'UTR',
  //     'Provider Id',
  //   ];
  //   // 
  //   const data = manageFilter.map((item: any, i: any) => {
  //     return [
  //       i + 1,
  //       item?.seller_name,
  //       item?.order_id,
  //       item?.seller_amount,
  //       item?.order_total,
  //       item?.settlement_status,
  //       item?.utr,
  //       item?.provider_id,
  //     ].join(',');
  //   });

  //   const csvContent = [headers.join(','), ...data].join('\n');
  //   const blob = new Blob([csvContent], { type: 'text/csv' });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = `orders_${new Date().getTime()}.csv`;
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // }


  const COLUMNS = [
    {
      Header: "Sn No",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Order ID",
      Footer: "Order ID",
      accessor: "order_id",
      sticky: "left"
    },
    {
      Header: "Total Amount (in Rs)",
      Footer: "Total Amount (in Rs)",
      accessor: (a: any) => parseInt(a.order_total).toFixed(2),
      sticky: "left"
    },
    {
      Header: "Buyer Commission (in Rs)",
      Footer: "Buyer Commission (in Rs)",
      accessor: (a: any) => a.buyer_amount ? a.buyer_amount : a.buyer_commission,
      sticky: "left"
    },
    {
      id: "settlestatus",
      Header: "Settle Status",
      Footer: "Settle Status",
      accessor: (a: any) => a.settlement_status === "1" ? "Settled" : "Not Settled",
      sticky: "left"
    },
    {
      Header: "Created Date",
      Footer: "Created Date",
      accessor: "createdAt",
      sticky: "left"
    },
    {
      Header: "Action",
      accessor: (a: any) => {
        return <><button className="btn btn-success btn-circle"
          onClick={() => { getTransactionbyId(a.order_id); modelOpen() }}
        >
          <i className="fas fa-pen"></i>
        </button></>
      },
      sticky: "left"
    },
  ];

  useEffect(() => {
    totalSettlementAmount();
  }, [startDate, endDate]);

  // console.log("settleCliccked", settleClicked)
  return (
    <>
      <ThemeMaker>
        <div className="row w-100 m-0 mt-2">
          <div className="col-md-12 ">
            <div className="shadow">
              <div className="card-header">
                <div className="row d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-info">
                    Settlement List
                  </h6>
                  <div>
                    {/* {isAdmin === "true" ? (
                                                <button className="btn btn-danger" onClick={() => exportToCSV(orderList)}>Download Sheet</button>
                                            ) : null} */}
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-6 col-md-6 col-sm-6">
                    <div className="col-6 col-md-6 p-0">
                      <label htmlFor="start-date">Search Filter </label>
                      <input type="search" className="form-control" placeholder="Search" id="" onChange={(event) => filterBySearch(event)} />
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end p-0">
                    <div className="col-6">
                      <label htmlFor="start-date"> Start Date : </label>
                      <input type="date" className="form-control" placeholder="Select a date" id=""
                        value={startDate} onChange={(e: any) => handleStartDateChange(e)} />
                    </div>
                    <div className="col-6">
                      <label htmlFor="end-date"> End Date : </label>
                      <input type="date" className="form-control" value={endDate} id="" onChange={(e: any) => handleEndDateChange(e)} />
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-md-6">
                    <ul className={`${Styles?.bloc_tabs} m-0`}>
                      <li className={toggleState === 1 ? `${Styles.tabs} ${Styles.active_tabs}` : Styles.tabs} onClick={() => toggleTab(1)}>
                        Un-Settled
                      </li>
                      {/* <li className={toggleState === 2 ? `${Styles.tabs} ${Styles.active_tabs}` : Styles.tabs} onClick={() => toggleTab(2)}>
                                                Order Wise Payments
                                            </li> */}
                    </ul>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end align-items-end">
                    {/* <button type="button" className="btn btn-outline-info btnHeight" onClick={() => exportToCSV(manageFilter)}>Download Details</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive table-bordered">
              {loading ? (
                // <Loader />
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
                  <CircularProgress />
                </div>
              ) : (
                <Paginated data={manageFilter} columns={COLUMNS} />
              )}
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={Mstyle}  >
                <div className="modal-header position-relative">
                  <p className="modal-title" style={{ fontSize: "14px", fontWeight: "500", color: "black" }}>Order id: <span className="bg-secondary text-white px-2 rounded-sm">{details[0]?.order_id}</span> </p>
                  <button type="button" className="close position-absolute" style={{ right: "0", top: "0" }} onClick={() => handleClose()}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                {!isDetailsLoading ? <TableContainer component={Paper}>
                  <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow >
                        <TableCell align="center" className="bg-light">Order Amount</TableCell>
                        <TableCell align="center" className="bg-light font-weight-bold" >
                          <span>&#8377;</span> {details[0]?.order_total}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="bg-light">TDS Deduction</TableCell>
                        {/* <TableCell align="center" className="bg-light font-weight-bold">{calculation.tds_deduction}</TableCell> */}
                        <TableCell align="center" className="bg-light font-weight-bold">
                          <input type="number" className="form-control" min={0} value={tdsValue} onKeyDown={preventMinus} onChange={(e: any) => settdsValue(e.target.value)} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="bg-light">TCS Deduction</TableCell>
                        {/* <TableCell align="center" className="bg-light font-weight-bold">{calculation.tcs_deduction}</TableCell> */}
                        <TableCell align="center" className="bg-light font-weight-bold">
                          <input type="number" className="form-control" min={0} value={tcsValue} onKeyDown={preventMinus} onChange={(e: any) => settcsValue(e.target.value)} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="bg-light">Buyer Commission ({details[0]?.buyer_commission_percentage}%)</TableCell>
                        <TableCell align="center" className="bg-light font-weight-bold"><span>&#8377;</span> {details[0]?.buyer_amount}</TableCell>
                      </TableRow>
                      <TableRow> <TableCell align="center" className="bg-light">GST On Buyer Commission(18%)</TableCell>
                        <TableCell align="center" className="bg-light font-weight-bold"><span>&#8377;</span> {calculateGST(details[0]?.buyer_amount, 18)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" className="bg-light">Seller Amount</TableCell>
                        <TableCell align="center" className="bg-light font-weight-bold" > <span>&#8377;</span> {details[0]?.seller_amount}</TableCell>
                      </TableRow>

                    </TableHead>

                  </Table>
                  <div className="d-flex justify-content-end p-2 w-100" style={{ gap: "13px" }}>
                    {!settleClicked ? (
                      details[0]?.receiveAmount === false ? (
                        <button className="btn btn-danger" onClick={receiveHandler}>Receive</button>
                      ) : (details[0]?.settlement_status !== "1" ? (
                        <button className="btn btn-success" onClick={() => {
                          setsettleClicked(true);
                        }}>Settle</button>
                      ) : null)
                    ) : null}
                    {
                      settleClicked ? <div className="d-flex justify-content-center" style={{ gap: "5px" }}>
                        <input type="text" className="form-control" placeholder="Transaction ID" onChange={(e) => { setTransactionid(e.target.value) }} />
                        <input type="date" name="" id="" className="form-control bg-white" max={getCurrentDate()} onChange={(e) => { setTransactionidDate(e.target.value) }} />
                        <button className="btn btn-primary" disabled={((Transactionid === "") || (TransactionidDate === "")) ? true : false} onClick={submitCheckedDataSingle}>Submit</button>
                      </div> : null
                    }
                  </div>
                </TableContainer> : null
                }
                {
                  isDetailsLoading ? <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div> : null
                }
              </Box>
            </Modal>
          </div>
        </div>

      </ThemeMaker >
    </>
  )
}

export default Settled;
