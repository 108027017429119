import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import config from "../../config/config";
import ThemeMaker from "../../components/thememaker";
import { Paginated } from "../../components/Paginated";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { toast } from "react-toastify";
var $ = require('jquery');

const UserList = () => {
    const { baseUrl } = config;
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { user } = decoded.data.permission;


    const history = useHistory();
    const [userList, setUserList] = useState([]);
    const [filterUser, setFilteredUser] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loading, setloading] = useState(true);
    const closeModel: any = useRef();

    const [error, setError] = useState('');
    const [_id, setId] = useState("");

    const getUserList = async () => {
        try {
            const { data: { data } } = await axios({
                url: `${baseUrl}/user/list`,
                method: "GET",
                headers: {
                    Authorization: ` ${token}`
                },
            });
            setUserList(data.filter((item: any) => item._id !== decoded.data._id).filter((item: any) => item._id !== '62a739cacf61d9b5638ab9fd'))
            setFilteredUser(data.filter((item: any) => item._id !== decoded.data._id).filter((item: any) => item._id !== '62a739cacf61d9b5638ab9fd'))
            setloading(false);
            setTimeout(() => {
                $('#dataTable').DataTable()
            }, 1000)
        } catch (error) {
            setloading(false);
            setError("error");
        }
    }
    const deleteUser = async () => {
        // $("#exampleModal").hide();
        //  alert(_id);
        try {
            setDeleteLoading(true);
            const { data } = await axios({
                url: `${baseUrl}/user/delete`,
                method: "DELETE",
                headers: {
                    Authorization: ` ${token}`
                },
                data: { _id }
            });
            setDeleteLoading(false);
            //$("#closebtn").click();
            toast.success("User Deleted successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setUserList(userList.filter((itm: any) => itm._id !== _id));
            getUserList();
            closeModel.current.click()
        } catch (error) {
            setDeleteLoading(false);
        }
    }
    const redirectToUserPage = (_id: any) => {
        const user = userList.find((item: any) => item._id === _id);
        localStorage.setItem('user', JSON.stringify(user));
        history.push(`/user/${_id}`);
    }
    // FIlter Search
    const filterBySearch = (event: any) => {
        const querySearch = event.target.value.toLowerCase();
        const SearchFiltere = userList?.filter((item: any) => {
            const fields = ['name', 'email', 'mobile', 'address', 'userType'];
            return fields.some((field: any) =>
                (item?.[field]?.toLowerCase() || '').includes(querySearch)
            );
        });
        setFilteredUser(SearchFiltere);
    };

    const COLUMNS = [
        {
            Header: "Sn.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Profile",
            accessor: (item: any) => (
                <div>
                    <img src={item?.profile ? item?.profile : '/img/undraw_profile.svg'} alt="noimh" style={{ height: "50px" }} />
                </div>
            ),
            sticky: "left",
        }
        ,
        {
            Header: "Name",
            accessor: (a: any) => (a?.name ? a?.name : ""),
            // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
            sticky: "left"
        },

        {
            Header: "Emails",
            accessor: (a: any) => (a?.email ? a?.email : ""),
            sticky: "left"
        },
        {
            Header: "Mobile",
            accessor: (a: any) => (a?.mobile ? a?.mobile : ""),
            sticky: "left"
        },
        {
            Header: "User Type",
            accessor: (item: any) => (item?.userType ? item?.userType : null),
            sticky: "left"
        },
        {
            Header: "Gender",
            accessor: (item: any) => (item?.gender ? item?.gender : null),
            sticky: "left"
        },
        {
            Header: "Actions",
            accessor: (item: any) => (
                <div className="row" style={{ width: "120px" }}>
                    <div className="col-md-12">
                        {decoded?.data?.isAdmin === "true" ? (
                            <Link to={`#`} onClick={() => redirectToUserPage(item?._id)} className="btn btn-primary justify-content-start btn-circle">
                                <i className="fas fa-edit"></i>
                            </Link>
                        ) : user?.update ? (
                            <Link to={`#`} onClick={() => redirectToUserPage(item?._id)} className="btn btn-primary justify-content-start btn-circle">
                                <i className="fas fa-edit"></i>
                            </Link>
                        ) : null}
                        {decoded?.data?.isAdmin === "true" ? (
                            <button
                                className="btn btn-danger btn-circle ml-2"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setId(item?._id)}
                            >
                                <i className="fas fa-trash"></i>
                            </button>
                        ) : user?.delete ? (
                            <button
                                className="btn btn-danger btn-circle ml-2"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setId(item?._id)}
                            >
                                <i className="fas fa-trash"></i>
                            </button>
                        ) : null}
                    </div>
                </div>
            ),
            sticky: "left",
        }

    ];

    useEffect(() => {
        getUserList()
    }, [])
    return (
        <>
            <ThemeMaker>
                <div className="row">
                    <div className="col-md-12 pl-4 pr-4">
                        <div className="card shadow mb-4 mt-1">
                            <div className="card-header py-3">
                                <div className="row d-flex justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-danger">
                                        User List
                                    </h6>
                                </div>
                                <div className="search mt-1">
                                    <div className="m-0 d-flex">
                                        {/* <div className="d-flex w-100"> */}
                                        <div className="">
                                            <label htmlFor="exampleInputEmail1">Search By  Name | Emails | Mobiles</label>
                                            <input type="search" name="search_user" placeholder="Search" className="mr-2 form-control" onChange={(event) => filterBySearch(event)} />
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-bordered">
                                    <div>
                                        {loading ? (
                                            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
                                                <CircularProgress />
                                            </div>
                                        ) : (
                                            filterUser && <Paginated data={filterUser} columns={COLUMNS} />
                                        )}
                                    </div>
                                </div>
                                {/* <div className="table-responsive">
                        {
                            loading ? <Loader /> : (
                                <table className="table table-bordered" id="dataTable" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>Sn</th>
                                            <th>Profile</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>User Type</th>
                                            <th>Gender</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>

                                    </tfoot>
                                    <tbody>
                                        {
                                            userList.map((item: any, index) => (
                                                <tr key={item._id} >
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <div>
                                                            <img src={`${item.profile ? item.profile : '/img/undraw_profile.svg'}`} alt="noimh" style={{ height: "50px" }} />
                                                        </div>
                                                    </td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.mobile}</td>
                                                    <td>{item?.userType}</td>
                                                    <td>{item?.gender}</td>
                                                    <td>
                                                        <div className="row" style={{ width: "120px" }} >
                                                            <div className="col-md-12">
                                                                {
                                                                    decoded.data.isAdmin === "true" ? (
                                                                        <Link to={`#`} onClick={() => redirectToUserPage(item._id)} className="btn btn-primary justify-content-start btn-circle">
                                                                            <i className="fas fa-edit"></i>
                                                                        </Link>
                                                                    ) : user.update ? <Link to={`#`} onClick={() => redirectToUserPage(item._id)} className="btn btn-primary justify-content-start btn-circle">
                                                                        <i className="fas fa-edit"></i>

                                                                    </Link> : null
                                                                }
                                                                {
                                                                    decoded.data.isAdmin === "true" ? (
                                                                        <button className="btn btn-danger btn-circle ml-2"
                                                                            data-toggle="modal" data-target="#exampleModal"
                                                                            onClick={() => setId(item._id)}
                                                                        >
                                                                            <i className="fas fa-trash"></i>
                                                                        </button>
                                                                    ) : user.delete ? <button className="btn btn-danger btn-circle ml-2"
                                                                        data-toggle="modal" data-target="#exampleModal"
                                                                        onClick={() => setId(item._id)}
                                                                    >
                                                                        <i className="fas fa-trash"></i>
                                                                    </button> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            )
                        }
                    </div> */}
                            </div>
                            <div className="modal fade" id="exampleModal" role="dialog">
                                <div className="modal-dialog modal-sm w-25" role="document"> 
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title text-center m-0 font-weight-bold text-danger">Confirmation</h5> 
                                            <button type="button" ref={closeModel} className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body text-center">
                                            <p>Are you sure you want to delete?</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-danger btn-sm" onClick={deleteUser}>
                                                {deleteLoading ? "Loading..." : "Delete"}
                                            </button>
                                            {deleteLoading ? null : (
                                                <button type="button" id="closebtn" className="btn btn-secondary btn-sm" data-dismiss="modal">
                                                    Close
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default UserList;