import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import config from "../../config/config";

const Profile = () => {
    const { baseUrl } = config;
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { data } = decoded;

    const rawData = {
        name: '',
        mobile: '',
        gender: '',
        profile: '',
        address: '',
    }
    const [userData, setUserData]: any = useState(rawData);
    const inputHandler = (e: any) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }

    const updateHandler = async () => {
        console.log("baseUrl", baseUrl);
        try {
            const { data } = await axios({
                url: `${baseUrl}/user/profile`,
                method: "PUT",
                headers: {
                    Authorization: token
                },
                data: userData
            });
            // console.log(data);
            if (data.message === "flag1") {
                toast.success("Profile updated successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("Error in updating user !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            toast.success("404 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    useEffect(() => {
        setUserData({
            name: data.name,
            mobile: data.mobile,
            gender: data.gender,
            profile: data.profile,
            address: data.address,
            _id: data._id
        });
    }, []);

    return (
        <>
            <ThemeMaker>
                <div className="row">
                    <div className="col-md-12 pl-4 pr-4">
                        <div className="card shadow mt-2 mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Profile
                                </h6>
                            </div>
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Name</label>
                                            <input type="text" className="form-control" name="name" placeholder="Enter name"
                                                value={userData.name}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Mobile</label>
                                            <input type="number" name="mobile" className="form-control" placeholder="Enter mobile"
                                                value={userData.mobile}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Gender</label>
                                            <div>
                                                <label htmlFor="">Male</label>
                                                <input type="radio" checked={userData.gender === "male" ? true : false} value="male" name="gender" className="ml-2"
                                                    onChange={(e) => inputHandler(e)}
                                                />
                                                <label htmlFor="" className="ml-2">Female</label>
                                                <input type="radio" checked={userData.gender === "female" ? true : false} value="female" className="ml-2" name="gender"
                                                    onChange={(e) => inputHandler(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Profile</label>
                                            <div className="d-flex" >
                                                <input type="file" className="form-control-file" />
                                                <img src="/img/undraw_profile.svg" alt="view img" style={{ height: "60px" }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label >Address</label>
                                            <textarea name="address" className="form-control" id=""
                                                value={userData.address}
                                                onChange={(e) => inputHandler(e)}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="btn btn-primary"
                                            onClick={updateHandler}
                                        >
                                            Update
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}


export default Profile;
