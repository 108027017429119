/* eslint-disable react-hooks/rules-of-hooks */
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { useState, useEffect, CSSProperties } from "react";
import ThemeMaker from "../../components/thememaker";
import Styles from "./style.module.css";
import axios from "axios";
import { Paginated } from "../../components/Paginated";
import "../../index.css";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import config from "../../config/config";
import jwt_decode from "jwt-decode";

const styles = {
    priceStyle: {
        whiteSpace: "nowrap"
    } as CSSProperties,
};
const index = () => {
    const { baseUrl } = config;
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { data: any } = decoded;
    const [toggleState, setToggleState] = useState(1);
    const [details, setDetails]: any = useState([]);
    const [isDetailsLoading, setIsDetailsLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [transactionDetails, setTransactionDetails]: any = useState([]);
    const [manageFilter, setManageFilter]: any = useState([]);
    const [settlementAmount, setSettlementAmount]: any = useState('');
    const [setAmount, setSetAmount]: any = useState({ returnSettlements: 0, inProcess: 0 });
    const [open, setOpen] = useState(false);
    const modelOpen = () => setOpen(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOption, setSelectedOption] = useState('');

    const handleClose = () => {
        setOpen(false);
    };
    const toggleTab = (e: any) => {
        setToggleState(e);
    };

    const Mstyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "40%",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    // 
    const handleStartDateChange = (event: any) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event.target.value);
    };

    // const getTransactionbyId = async (order_id: any) => {
    //     try {
    //         setIsDetailsLoading(true)
    //         const { data } = await axios({
    //             url: `${apiEndpoint}/transactiondetail`,
    //             method: "POST",
    //             headers: {
    //                 Authorization: `Bearer ${Cookies.get("us_st_d")}`,
    //             },
    //             data: {
    //                 order_id
    //             }
    //         });
    //         setDetails(data?.data)
    //         setIsDetailsLoading(false)
    //     } catch (error) {
    //         console.log("Something Went Wrong!", error)
    //         setIsDetailsLoading(false)
    //     }
    // }

    const returnAmount = async () => {
        try {
            setIsDetailsLoading(true)
            console.log("startDate", startDate, "endDate", endDate)
            const { data } = await axios({
                url: `${baseUrl}/v1/retrunsettlement`,
                method: "POST",
                headers: {
                    Authorization: token,
                },
                data: {
                    startDate: startDate,
                    endDate: endDate
                }
            });
            const hasData = data?.data && data?.data?.length > 0;
            setTransactionDetails(hasData ? [...(data?.data || [])] : []);
            setManageFilter(hasData ? [...(data?.data || [])] : []);
            setIsDetailsLoading(false)
        } catch (error) {
            console.log("Something Went Wrong!", error)
            setIsDetailsLoading(false)
        }
    };
    const handleSelectChange = (e: any) => {
        setSelectedOption(e.target.value);
        setManageFilter(transactionDetails)
        setSearchQuery('')
    };

    // FILTER
    const filterBySearch = (event: any) => {
        setSearchQuery(event.target.value)
        const querySearch = event.target.value.toLowerCase();
        const filteredResults = transactionDetails.filter((item: any) => {
            switch (selectedOption) {
                case 'orderId':
                    return item?.shopifyorderid?.toLowerCase().includes(querySearch);
                case 'amount':
                    return item?.price?.toString().toLowerCase().includes(querySearch);
                // case 'settlementStatus':
                //     return item?.status?.toString().toLowerCase().includes(querySearch);
                default:
                    return (
                        item?.shopifyorderid.toLowerCase().includes(querySearch.toLowerCase()) ||
                        item?.price?.toString().toLowerCase().includes(querySearch.toLowerCase())
                    );
            }
        });
        setManageFilter(filteredResults);
    };

    const calculateSellerAmount = (manageFilter: any) => {
        const allowedStatuses = ["Return_Approved", "return", "Pending", "Serviceable", "Order-delivered", "created", "Accepted", "Cancelled", "Return_intiated"];
        const { returnSettlements, inProcess } = manageFilter?.reduce((accumulator: any, currentTransaction: any) => {
            const amount = parseFloat(currentTransaction?.price);
            if (currentTransaction && (allowedStatuses?.includes(currentTransaction?.status) || currentTransaction?.status === null)) {
                accumulator.inProcess += amount;
            }
            if (currentTransaction?.status === "Liquidated" || currentTransaction?.status === "Completed" || currentTransaction?.status === "Cancelled") {
                accumulator.returnSettlements += amount;
            }
            return accumulator;
        }, { returnSettlements: 0, inProcess: 0 });
        return { returnSettlements, inProcess }
    };

    const exportToCSV = (orderList: any) => {
        const headers = [
            'S.No',
            'Company Name	',
            'Order Id',
            'Receive Amount',
            'Order Total',
            'Settlement Status',
            'UTR',
            'Provider Id',
        ];
        const data = manageFilter.map((item: any, i: any) => {
            return [
                i + 1,
                item?.company_name,
                item?.order_id,
                item?.seller_amount,
                item?.order_total,
                item?.settlement_status,
                item?.utr,
                item?.provider_id,
            ].join(',');
        });

        const csvContent = [headers.join(','), ...data].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `orders_${new Date().getTime()}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const reasonCodeMap: any = {
        "001": "Price of one or more items have changed due to which buyer was asked to make additional payment",
        "002": "One or more items in the Order not available",
        "003": "Product available at lower than order price",
        "005": "Merchant rejected the order",
        "006": "Order / fulfillment not received as per buyer app TAT SLA",
        "007": "Order / fulfillment not received as per buyer app TAT SLA",
        "008": "Order not ready for pickup",
        "009": "Wrong product delivered",
        "010": "Buyer wants to modify address / other order details",
        "011": "Buyer not found or cannot be contacted",
        "013": "Buyer refused to accept delivery",
        "014": "Address not found",
        "015": "Buyer not available at location",
        "016": "Accident / rain / strike / vehicle issues",
        "017": "Order delivery delayed or not possible",
        "018": "Delivery pin code not serviceable",
        "019": "Pickup pin code not serviceable",
        "020": "Order lost in transit",
        "021": "Packed order not complete",
    };

    const COLUMNS = [
        {
            Header: "Sn.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Order Id",
            accessor: (a: any) => (a?.ondcorderid ? a?.ondcorderid : ""),
            sticky: "left"
        },
        {
            Header: "Return Amount",
            accessor: (a: any) => a?.price ? `${a.price} ₹` : null,
            Cell: ({ value }: any) => (
                <div className="price-cell" style={styles?.priceStyle}>
                    {value}
                </div>
            ),
            sticky: "left"
        },
        {
            Header: "Order Status",
            accessor: (item: any) => (item?.status ? item?.status : ""),
            sticky: "left"
        },
        {
            Header: "Reason",
            accessor: (item: any) => (item?.cancellation_reason_id ? reasonCodeMap[item?.cancellation_reason_id] || null : ' '),
            sticky: "left"
        }
        // {
        //     Header: "Action",
        //     accessor: (a: any) => {
        //         return <><button className="btn btn-success btn-circle"
        //             onClick={() => { getTransactionbyId(a.order_id); modelOpen() }}
        //         >
        //             <i className="fas fa-pen"></i>
        //         </button></>
        //     },
        //     sticky: "left"
        // },
    ];

    useEffect(() => {
        returnAmount();
    }, [startDate, endDate])

    useEffect(() => {
        const result = calculateSellerAmount(manageFilter);
        setSetAmount(result);
    }, [manageFilter]);

    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-2">
                    <div className="col-md-12">
                        <div className="shadow mb-4">
                            <div className="card-header">
                                <div className="row d-flex justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-info">Payments</h6>
                                    {/* {toggleState === 1 ? <div className="col-sm-6 col-6 col-md-6 d-flex justify-content-end align-items-end p-0">
                                        <button type="button" className="badge btn btn-outline-info btnHeight" onClick={() => exportToCSV(manageFilter)}>Download Details</button>
                                    </div> : null
                                    } */}
                                </div>
                                <div className="row justify-content-between">
                                    {/* <div className="col-md-4 col-sm-12 col-12">
                                        <label htmlFor="start-date">Search Filter </label>
                                        <input type="search" className="form-control" placeholder="Search" id="" onChange={(event) => filterBySearch(event)} />
                                    </div> */}
                                    <div className="col-6 col-md-6 col-sm-6">
                                        <label htmlFor="searchInput" >Search by :</label>
                                        <div className={` ${Styles?.search_input_container}`}>
                                            <select value={selectedOption}
                                                onChange={handleSelectChange}
                                                className={`${Styles?.search_select} bg-gradient-light m-0`}>
                                                <option value="" selected>Select By Search</option>
                                                {/* <option value="companyName">Company Name</option> */}
                                                <option value="orderId">Order Id</option>
                                                <option value="amount">Receive Amount</option>
                                                {/* <option value="settlementStatus">Settlement Status</option> */}
                                            </select>
                                            {selectedOption === 'settlementStatus' ? (
                                                <select value={searchQuery} onChange={(event) => filterBySearch(event)}
                                                    className={`${Styles?.search_select} bg-transparent form-control`}>
                                                    <option value="" selected>Select Option</option>
                                                    <option value="Completed">Completed</option>
                                                    <option value="Liquidated">Liquidated</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Return_Approved">Return Approved</option>
                                                    <option value="return">Return</option>
                                                    <option value="Serviceable">Serviceable</option>
                                                    <option value="Order-delivered">Order delivered</option>
                                                    <option value="created">created</option>
                                                    <option value="Accepted">Accepted</option>
                                                </select>
                                            ) : (
                                                <input type="search" value={searchQuery} className={`${Styles?.search_input} bg-transparent form-control`} placeholder="Search query..." id=""
                                                    onChange={(event) => filterBySearch(event)} />
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-end p-0">
                                        <div className="col-6">
                                            <label htmlFor="start-date"> Start Date : </label>
                                            <input type="date" className="form-control" placeholder="Select a date" id=""
                                                value={startDate} onChange={(e: any) => handleStartDateChange(e)} />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="end-date"> End Date : </label>
                                            <input type="date" className="form-control" value={endDate} id="" onChange={(e: any) => handleEndDateChange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1 mb-3">
                                    <div className="col-sm-12 col-md-6">
                                        <ul className={`${Styles?.bloc_tabs} m-0`}>
                                            <li className={toggleState === 1 ? `${Styles.tabs} ${Styles.active_tabs}` : Styles.tabs} onClick={() => toggleTab(1)}>
                                                Settlements
                                            </li>
                                            {/* <li className={toggleState === 2 ? `${Styles.tabs} ${Styles.active_tabs}` : Styles.tabs} onClick={() => toggleTab(2)}>
                                                Settlement Return
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                                <hr />
                                <div className={`${Styles.content_tabs}`}>
                                    <div className={toggleState === 1 ? `${Styles.contentShow}  ${Styles.active_content}` : `${Styles.contentShow}`}>
                                        <div className="row " style={{ gap: "10px" }}>
                                            <div className="col-12 col-md-4 ">
                                                <div className="card border-primary mb-3 bg-success ">
                                                    <div className="card-body text-light">
                                                        <small className="card-text">Total Return Settlements</small>
                                                        <h5 className="card-title">₹ {isDetailsLoading ? <small>Loading</small> : parseFloat(setAmount?.returnSettlements || 0)?.toFixed(2)} </h5>
                                                        <small className="card-text">Amount Credit Your Account</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="card border-secondary mb-3 bg-transparent ">
                                                    <div className="card-body text-warning">
                                                        <small className="card-text text-secondary">Amount in Process</small>
                                                        <h5 className="card-title">₹ {isDetailsLoading ? <small>Loading</small> : parseFloat(setAmount?.inProcess || 0)?.toFixed(2)}</h5>
                                                        <small className="card-text text-secondary">(Amount will be Credited in 2-5 days.)</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive table-bordered">
                                            <Paginated data={manageFilter} columns={COLUMNS} />
                                        </div>
                                    </div>
                                    {/*  <div className={toggleState === 2 ? `${Styles.contentShow}  ${Styles.active_content}` : `${Styles.contentShow}`}>
                                        <div className="container">
                                            <div className="row" style={{ gap: "10px" }}>
                                                <div className="card border-primary mb-3 w-25 bg-success">
                                                    <div className="card-body text-light">
                                                        <small className="card-text">Total Amout Settlements</small>
                                                        <h5 className="card-title">₹ {isDetailsLoading ? <small>Loading</small> : parseFloat(settlementAmount?.settled_amount || 0)?.toFixed(2)} </h5>
                                                        <small className="card-text">Amount Credit Your Account</small>
                                                    </div>
                                                </div>
                                                <div className="card border-secondary mb-3 w-25 bg-transparent" >
                                                    <div className="card-body text-warning">
                                                        <small className="card-text text-secondary">Amount in Process</small>
                                                        <h5 className="card-title">₹ {isDetailsLoading ? <small>Loading</small> : parseFloat(settlementAmount?.inprocess_amount || 0)?.toFixed(2)}</h5>
                                                        <small className="card-text text-secondary">(Amount will be Credited in 2-5 days.)</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Modal open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <Box sx={Mstyle}>
                        <div className="modal-header position-relative">
                            <p className="modal-title " style={{ fontSize: "14px", fontWeight: "500", color: "black" }}>Company Name - {details?.company_name} </p>
                            <button type="button" className="close position-absolute" style={{ right: "0", top: "0" }} onClick={() => handleClose()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {!isDetailsLoading ? <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell align="center" className="bg-light">Setteled Amount</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold" >
                                            {details?.setteled_amount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell align="center" className="bg-light">Recieved Amount</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold" >
                                            {details?.recieved_amt}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell align="center" className="bg-light">Ondc Gateway Amount</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold" >
                                            {details?.ondc_gateway_amount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" className="bg-light">TDS Deduction</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold">{details?.tcs}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" className="bg-light">TCS Deduction</TableCell>
                                        <TableCell align="center" className="bg-light font-weight-bold">{details?.tds}</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer> : null}
                        {
                            isDetailsLoading ? <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div> : null
                        }
                    </Box>
                </Modal>
            </ThemeMaker >
        </>
    )
}

export default index;