/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef, CSSProperties } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import config from "../../config/config"
import moment from "moment";
import ThemeMaker from "../../components/thememaker";
import { CircularProgress } from "@mui/material";
import { Paginated } from "../../components/Paginated";
var $ = require('jquery');

const styles = {
    productImgStyle: {
        height: "50px",
    } as CSSProperties,
    priceStyle: {
        whiteSpace: "nowrap"
    } as CSSProperties,
};

const BuyerFee = () => {
    const { baseUrl } = config;

    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const [productList, setProductList] = useState([]);
    const [SearchList, setSearchList] = useState([]);
    const [loading, setloading] = useState(true);
    const [error, setError] = useState('');

    const getBuyerFee = async () => {
        try {
            const { data } = await axios({
                url: `${baseUrl}/buyerdetails`,
                method: "GET",
                headers: {
                    Authorization: token
                },
            });
            setProductList(data ? data : []);
            setSearchList(data ? data : []);
            setloading(false);
        } catch (error) {
            setloading(false);
            setError("error");
        }
    }

    const priceStyle = {
        whiteSpace: "nowrap",
    }

    useEffect(() => {
        getBuyerFee();
    }, [])

    // FIlter Search
    const filterBySearch = (event: any) => {
        const querySearch = event.target.value;
        // const querySearch = event.target.value.toLowerCase();
        let SearchFiltere = productList.filter((item: any) => {
            const priceAsString = String(item?.finder_fee_amount);
            const lowerCasedQuery = querySearch.toLowerCase();
            return (
                item?.bap_id?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                priceAsString.includes(lowerCasedQuery)
            );
        });
        setSearchList(SearchFiltere);
    };

    const COLUMNS = [
        {
            Header: "Sn.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: false,
            sticky: "left"
        },
        {
            Header: " ONDC Buyer",
            accessor: (item: any) => (item?.bap_id ? item?.bap_id : ""),
            sticky: "left"
        },
        {
            Header: "Percentage",
            accessor: (item: any) => (item?.finder_fee_amount ? `${item?.finder_fee_amount}%` : null),
            sticky: "left",
            Cell: ({ value }: any) => (
                <div className="price-cell" style={styles?.priceStyle}>
                    {value}
                </div>
            )
        },
        // {
        //     Header: "Date",
        //     accessor: (item: any) => (item?.date ?  moment(item?.date).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : ""),
        //     sticky: "left"
        // }
    ];

    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow ">
                            <div className="card-header">
                                <h6 className="m-0 font-weight-bold text-danger text-left">Buyer Finder Fee List</h6>
                                <div className="search mt-1">
                                    <div className="row m-0 d-flex justify-content-center">
                                        <div className="d-flex w-100">
                                            <div className="col-md-4">
                                                <label htmlFor="exampleInputEmail1">Search By </label>
                                                <input type="search" name="order_id" placeholder="Search" className="mr-2 form-control"
                                                    onChange={(event) => filterBySearch(event)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    {
                                        loading ? <Loader /> : (
                                            <div className="table-responsive table-bordered ">
                                                {!loading ? <Paginated data={SearchList} columns={COLUMNS} /> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default BuyerFee;