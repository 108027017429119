
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";


const LoginPageIsLogedIn = ({ children }: any) => {
    const history = useHistory();
    const token: any = sessionStorage.getItem("isLogedIn");
    try {
      //  console.log(token);
        var decoded = jwt_decode(token);
     //   console.log(decoded);
        if (decoded) {
            history.push("/");
        } else {
            return children;
        }
    } catch (error) {
        return children;
    }
}

export default LoginPageIsLogedIn;