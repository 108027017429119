import { useState, useEffect } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import config from "../../config/config"


interface ICustomer {
    id: any;

}
const AddUser = () => {
    const { baseUrl } = config;
    let params: ICustomer = useParams();
    const { id } = params;
    const [pageNamem, setpageName] = useState(true);

    const [loading, setLoading] = useState(false);
    const [uploading, setUpLoading] = useState(false);

    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { data } = decoded;

    const rawData = {
        name: '',
        email: '',
        mobile: '',
        userType: '',
        gender: '',
        profile: '',
        address: '',
        permission: {
            product: {
                read: false,
                write: false,
                update: false,
                delete: false
            },
            user: {
                read: false,
                write: false,
                update: false,
                delete: false
            },
            order: {
                read: false
            },
            order_manage: {
                read: false
            },
            support: {
                read: false
            },
            igm: {
                read: false,
            },
        }
    }
    const [userData, setUserData]: any = useState(rawData);

    const inputHandler = (e: any) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    }

    const newUserCreatehandler = async () => {
        debugger
        setLoading(true);
        try {
            const { data } = await axios({
                url: `${baseUrl}/user/create`,
                method: "POST",
                headers: {
                    Authorization: ` ${token}`
                },
                data: userData
            });
            if (data.flag === "flag1") {
                toast.error("Email already exist", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setUserData(rawData);
            } else if (data.flag === "flag2") {
                toast.success("User created successfully", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("404 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        // console.log(userData);
    }
    const updateUserHandler = async () => {
        try {
            setUpLoading(true);
            await axios({
                url: `${baseUrl}/user/update`,
                method: "PUT",
                headers: {
                    Authorization: ` ${token}`
                },
                data: userData
            });
            setUpLoading(false);
            localStorage.setItem("user", JSON.stringify(userData));
            toast.success("User updated successfully !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            // history.push("/userlist");
        } catch (error) {
            setUpLoading(false);
            toast.error("404 error ! please reach to support@ens.enterprises", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    useEffect(() => {
        if (id) {
            setpageName(false);
            const user: any = localStorage.getItem("user")
            console.log();
            setUserData(JSON.parse(user))
        } else {
            setpageName(true)
        }
    }, [id]);
    
    return (        
        <>
            <ThemeMaker>
                <div className="row">
                    <div className="col-md-12 pl-4 pr-4">
                        <div className="card shadow mb-4 mt-1">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-danger">
                                    {
                                        pageNamem ? "Add A New User" : "Update User"
                                    }
                                </h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Name</label>
                                            <input type="text" className="form-control" name="name" placeholder="Enter name"
                                                value={userData.name}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Email address</label>
                                            <input type="text" name="email" className="form-control" placeholder="Enter email"
                                                value={userData.email}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Mobile</label>
                                            <input type="number" name="mobile" className="form-control" placeholder="Enter mobile"
                                                value={userData.mobile}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >User type</label>
                                            <select className="form-control" name="userType" id="exampleFormControlSelect1"
                                                value={userData.userType}
                                                onChange={(e) => inputHandler(e)}
                                            >
                                                <option value="0" >Select user type</option>
                                                <option value="admin" >Admin</option>
                                                <option value="user" >User</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Gender</label>
                                            <div>
                                                <label htmlFor="">Male</label>
                                                <input type="radio" checked={userData.gender === "male" ? true : false} value="male" name="gender" className="ml-2"
                                                    onChange={(e) => inputHandler(e)}
                                                />
                                                <label htmlFor="" className="ml-2">Female</label>
                                                <input type="radio" checked={userData.gender === "female" ? true : false} value="female" className="ml-2" name="gender"
                                                    onChange={(e) => inputHandler(e)}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Profile</label>
                                            <div className="d-flex" >
                                                <input type="file" className="form-control-file" />
                                                <img src="/img/undraw_profile.svg" alt="view img" style={{ height: "60px" }} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <img src="/img/undraw_profile.svg" alt="view img" style={{ height: "60px" }} />
                                            </div>
                                        </div> */}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label >Address</label>
                                            <textarea name="address" className="form-control" id=""
                                                value={userData.address}
                                                onChange={(e) => inputHandler(e)}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <label className="" >Permission</label>
                                        <div className="row md-3">
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="font-weight-bold" >Product</label>
                                                    <ul className="ml-4" >
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission.product.read} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        product: {
                                                                            ...userData.permission.product,
                                                                            read: !userData.permission.product.read
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Read</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission.product.write} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        product: {
                                                                            ...userData.permission.product,
                                                                            write: !userData.permission.product.write
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Write</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission.product.update} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        product: {
                                                                            ...userData.permission.product,
                                                                            update: !userData.permission.product.update
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Update</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission.product.delete} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        product: {
                                                                            ...userData.permission.product,
                                                                            delete: !userData.permission.product.delete
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Delete</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="font-weight-bold" >User</label>
                                                    <ul className="ml-4" >
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission.user.read} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        user: {
                                                                            ...userData.permission.user,
                                                                            read: !userData.permission.user.read
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Read</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission.user.write} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        user: {
                                                                            ...userData.permission.user,
                                                                            write: !userData.permission.user.write
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Write</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission.user.update} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        user: {
                                                                            ...userData.permission.user,
                                                                            update: !userData.permission.user.update
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Update</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission.user.delete} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        user: {
                                                                            ...userData.permission.user,
                                                                            delete: !userData.permission.user.delete
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Delete</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="font-weight-bold" >Order</label>
                                                    <ul className="ml-4" >
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission?.order?.read} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        order: {
                                                                            ...userData.permission.order,
                                                                            read: !userData.permission?.order?.read
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Read</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData?.permission?.order?.write} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData?.permission,
                                                                        order: {
                                                                            ...userData?.permission?.order,
                                                                            write: !userData?.permission?.order?.write
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Write</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData?.permission?.order?.update} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData?.permission,
                                                                        order: {
                                                                            ...userData?.permission?.product,
                                                                            update: !userData?.permission?.order?.update
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Update</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData?.permission?.order?.delete} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData?.permission,
                                                                        order: {
                                                                            ...userData?.permission?.order,
                                                                            delete: !userData?.permission?.order?.delete
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Delete</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="font-weight-bold" >Order Manage</label>
                                                    <ul className="ml-4" >
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission?.order_manage?.read} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        order_manage: {
                                                                            ...userData.permission.order_manage,
                                                                            read: !userData.permission?.order_manage?.read
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Read</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData?.permission?.order_manage?.write} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData?.permission,
                                                                        order_manage: {
                                                                            ...userData?.permission?.order_manage,
                                                                            write: !userData?.permission?.order_manage?.write
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Write</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData?.permission?.order_manage?.update} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData?.permission,
                                                                        order_manage: {
                                                                            ...userData?.permission?.order_manage,
                                                                            update: !userData?.permission?.order_manage?.update
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Update</label>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" checked={userData?.permission?.order_manage?.delete} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData?.permission,
                                                                        order_manage: {
                                                                            ...userData?.permission?.order_manage,
                                                                            delete: !userData?.permission?.order_manage?.delete
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Delete</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="font-weight-bold" >IGM</label>
                                                    <ul className="ml-4" >
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission?.igm?.read} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        igm: {
                                                                            ...userData.permission?.igm,
                                                                            read: !userData.permission?.igm?.read
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Read</label>
                                                        </li>
                                                        {/* <li>
                                                            <input type="checkbox" checked={userData?.permission?.igm?.write} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData?.permission,
                                                                        igm: {
                                                                            ...userData?.permission?.igm,
                                                                            write: !userData?.permission?.igm?.write
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Write</label>
                                                        </li> */}
                                                        {/* <li>
                                                            <input type="checkbox" checked={userData?.permission?.igm?.update} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData?.permission,
                                                                        igm: {
                                                                            ...userData?.permission?.igm,
                                                                            update: !userData?.permission?.igm?.update
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Update</label>
                                                        </li> */}
                                                        {/* <li>
                                                            <input type="checkbox" checked={userData?.permission?.igm?.delete} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData?.permission,
                                                                        igm: {
                                                                            ...userData?.permission?.igm,
                                                                            delete: !userData?.permission?.igm?.delete
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Delete</label>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="font-weight-bold" >Support</label>
                                                    <ul className="ml-4" >
                                                        <li>
                                                            <input type="checkbox" checked={userData.permission?.support?.read} className="form-check-input"
                                                                onChange={(e) => setUserData({
                                                                    ...userData, permission: {
                                                                        ...userData.permission,
                                                                        support: {
                                                                            ...userData.permission.support,
                                                                            read: !userData.permission?.support?.read
                                                                        }
                                                                    }
                                                                })}
                                                            />
                                                            <label >Read</label>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-12 text-lg-right">
                                        {
                                            pageNamem ? (
                                                <button type="submit" className="btn btn-primary"
                                                    onClick={newUserCreatehandler}>
                                                    {
                                                        loading ? 'saving...' : "Save"
                                                    }
                                                </button>
                                            ) : (
                                                <button type="submit" className="btn btn-success"
                                                    onClick={updateUserHandler}
                                                >
                                                    {
                                                        uploading ? 'Loading...' : "Update"
                                                    }
                                                </button>
                                            )
                                        }
                                    </div>
                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ThemeMaker>
        </>
    )
}

export default AddUser;