import { useState, useEffect, useRef, CSSProperties } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "./orderlistPopus.css";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import config from "../../config/config";
import { Modal as AntModal } from "antd"
import { Paginated } from "../../components/Paginated";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
var $ = require("jquery");
// import config from "../../config/config";

const styles = {
  detailsStyle: {
    display: 'grid',
    justifyItems: "stretch",
    alignItems: "center",
  } as CSSProperties,
  headingStyle: {
    color: "#a00505e0",
    display: "flex",
    justifyContent: "space-between"
  } as CSSProperties,
}

const OrderList = () => {
  const { baseUrl } = config;
  const [popup, setPopup] = useState(false);
  const [orderStatusPopup, setOrderStatusPopup] = useState(false);
  const [cancelOrderPopup, setCancelOrderPopup] = useState(false);
  const [orderFulfillmentPopup, setOrderFulfillmentPopup] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [orderPopup, setOrderPopup] = useState([]);
  const [context, setContext] = useState({});
  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);
  const { user, product, order, order_manage } = decoded.data.permission;
  const history = useHistory();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [orderList, setOrderList] = useState([]);
  const [managefilter, setManageFilter] = useState([]);
  const [downloadcsv, setDownloadcsv] = useState({});
  const [loading, setloading] = useState(true);
  const closeModel: any = useRef();
  const [error, setError] = useState("");
  const [_id, setId] = useState("");
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [fullFillmentStatus, setFullFillmentStatus] = useState("");
  const [status, setstatus] = useState();
  const [reason_code, setreason_code] = useState();
  const [orderid, setorderid] = useState("");
  const [shopifyorderid, setshopifyorderid] = useState("");
  const [dynamicValue, setDynamicValue] = useState("In-progress");
  const [timestamp, setTimestamp] = useState("");


  const handleOk = () => {
    setOrderStatusPopup(false);
  };
  const handleCancel = () => {
    setOrderStatusPopup(false);
  };

  const handleClickPop = async (id: any) => {
    setPopup(!popup);
    try {
      const { data } = await axios({
        url: `${baseUrl}/orders/order_details`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: {
          order_id: id,
        },
      });
      if (data.status === 200) {
        setOrderPopup(data.data.reverse());
      } else {
        toast.error("404 not found !");
      }
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) {
      setloading(false);
    }
  };
  const closePopup = () => {
    setPopup(false);
  };

  const getOrderList = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseUrl}/orders/npdata`,
        method: "GET",
        headers: {
          Authorization: ` ${token}`
        },
      });
      setOrderList(data.reverse());
      setManageFilter(data.reverse());
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) {
      setloading(false);
      setError("error");
    }
  };
  const DownloadCsv = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseUrl}/orders/exportcsv`,
        // url:"localhost:9095/orders/exportcsv",
        method: "GET",
        headers: {
          "Content-Type": "application/csv",
        },
      });

      setDownloadcsv(data);
    } catch (error) {
      console.log("erroe", error);
    }
  };

  useEffect(() => {
    getOrderList();
    //  DownloadCsv();
  }, []);

  useEffect(() => {
    // console.log("status", status);
    if (status === "Accepted") {
      setFullFillmentStatus("Pending");
    }
    if (status === "In-progress") {
      setFullFillmentStatus("Order-picked-up");
    }
    if (status === "Completed") {
      setFullFillmentStatus("Order-delivered");
    }
    if (status === "Cancelled") {
      setFullFillmentStatus("Cancelled");
    }
  }, [status]);

  const changeStatus = async () => {
    try {
      const { data } = await axios({
        url: `${baseUrl}/orders/orderstatus`,
        method: "PUT",
        headers: {
          Authorization: token
        },
        data: {
          order_id: orderid,
          orderStatus: status,
          fullFillmentStatus: fullFillmentStatus,
          // timestamp: timestamp,
          // cancellation_reason_id: cancellation_reason_id,
        },
      });
      if (data.status === 200) {
        setUpdating(true);
        toast.success("Order status updated !");
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setOrderStatusPopup(false);
        await changeStatusseller(orderid, context);
        setUpdating(false);
      } else {
        toast.error("404 not found !");
      }
      // setTimeout(() => {
      //   $("#dataTable").DataTable();
      // }, 1000);
      setUpdating(false);
    } catch (error) { }
  };

  const changeStatusseller = async (orderid: any, context: any) => {
    context.message_id = uuidv4();
    const contextdata = {
      context,
      message: {
        order_id: orderid,
      },
    };
    // console.log("contaxtdata", contextdata);
    try {
      const { data } = await axios({
        url: `${baseUrl}/unsolicated_status`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: contextdata,
      });
      toast.success("Status updated by seller app");
      setUpdating(false);
    } catch (error: any) {
      console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };

  const changeUpdate_Unsolicated = async (ondcorderid: any) => {
    // console.log("contaxtdata22", ondcorderid);
    try {
      const { data } = await axios({
        url: `${baseUrl}/Unsolicated_Update`,
        // url: `https://ondc.hulsecure.in/Unsolicated_Update`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: { ondcorderid },
      });
      toast.success("Status updated by seller app");
    } catch (error: any) {
      console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };

  const ChangeStatusPopup = (ondcorderid: any, orderStatus: any, context: any) => {
    console.log(">>>>>>>>>>", orderStatus);
    setOrderStatusPopup(true);
    setorderid(ondcorderid);
    setContext(context);
    setstatus(orderStatus);
    setDynamicValue(orderStatus);
  };

  const closePop = () => {
    setOrderStatusPopup(false);
  };


  const CancelOrderPopup = (ondcorderid: any) => {
    // console.log("object", `${ondcorderid}`)
    setCancelOrderPopup(true);
    setorderid(ondcorderid);
    setshopifyorderid(shopifyorderid);
    setreason_code(reason_code);
  };
  const closeCancelPop = () => {
    setCancelOrderPopup(false);
  };

  const cancelOrder = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to cancel this order?"
    );
    if (confirmed) {
      debugger
      try {
        const response = await axios.post(`${baseUrl}/orders/cancelorder`, {
          ondcorderid: orderid,
          reason_code: reason_code,
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
        });

        if (response.status === 200) {
          toast.success("Order Canceled successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Failed to cancel order", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        getOrderList();
        closeModel.current.click()
      } catch (error) {
        console.error('An error occurred:', error);
        return { success: false, message: 'An error occurred' };
      }
    }
  };

  const Cancel_Item = async (order_id: any, item_id: any) => {
    // console.log("nothing data");
    // console.log("orderId", order_id);
    // console.log("item_id", item_id);
    // context.message_id = uuidv4();
    const contextdata = {
      order_id,
      item_id,
      status: "Cancelled",
    };
    console.log("contaxtdata", contextdata);
    try {
      const { data } = await axios({
        url: `${baseUrl}/orders/cancel-item`,
        method: "PUT",
        headers: {
          Authorization: ` ${token}`
        },
        data: contextdata,
      });

      // console.log("sas => ", data);
      toast.success("Status updated by seller app");
      // console.log("data>>>>>>", data);
      await changeUpdate_Unsolicated(order_id);
    } catch (error: any) {
      console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };

  // Filter
  const handleStartDateChange = (event: any) => {
    setStartDate(event.target.value);
    filterData(event.target.value, endDate);
  };

  const handleEndDateChange = (event: any) => {
    setEndDate(event.target.value);
    filterData(startDate, event.target.value);
  };
  // filter By Dates
  const filterData = (start: any, end: any) => {
    const startFilter = start ? new Date(start) : null;
    const endFilter = end ? new Date(end) : null;
    const filtered = orderList.filter((item: any) => {
      const itemDate = new Date(item.createdAt);
      return (
        (!startFilter || itemDate >= startFilter) && (!endFilter || itemDate <= endFilter)
      );
    });
    setManageFilter(filtered);
  };

  // FILTER
  const filterBySearch = (event: any) => {
    const querySearch = event.target.value;
    let SearchFiltere = orderList.filter((item: any) => {
      return (
        item?.ondcorderid.toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.status.toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.ondcorderid?.toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.message?.order?.items[0]?.fulfillment_id?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response.message.order.quote.breakup[0].title?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.message?.order?.billing?.address?.area_code?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.message?.order?.payment?.status?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.context?.bap_id?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.delivery_city?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.shopifyorderef?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.sku?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        `${item?.response?.message?.order.billing.name.slice(0, 4)}-${item?.response?.message?.order?.billing?.phone?.slice(-5)}`.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.context?.transaction_id?.toString().toLowerCase().includes(querySearch.toLowerCase())
      );
    });
    setManageFilter(SearchFiltere);
  };



  const COLUMNS = [
    {
      Header: "Sn.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Network Order Id",
      // accessor: (a: any) => (a ? "buyer-app-preprod.ondc.org" : ""),
      accessor: (item: any) => (`${item?.ondcorderid}`),
      sticky: "left"
    },
    {
      Header: "Order Create Date & Time",
      accessor: (a: any) => a?.createdAt ? moment(a.createdAt).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : null,
      // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "Ageing (in days)",
      Footer: "Ageing",
      accessor: (row: any) => {
        if (row.status === "Completed" || row.status === "Cancelled") {

          var date1 = moment(row.createdAt);
          var date2 = moment(row.updatedAt);
          var days = date2.diff(date1, 'days')
          return days
        } else {

          var date1 = moment(row.createdAt);
          var date2 = moment(new Date());
          var days = date2.diff(date1, 'days')
          return days
        }

      },
      sticky: "left"
    },
    {
      Header: "Customer Unique Identifier[First 4 Char of Buyer Name + Last 5 Digit of Ph]",
      accessor: (item: any) => {
        const order = item?.response?.message?.order;
        const billing = order?.billing;
        const buyerName = billing?.name?.slice(0, 4);
        const buyerPhone = billing?.phone;
        return `${buyerName}-${buyerPhone?.split("").reverse().slice(0, 5).reverse().join("")}`;
      },
      sticky: "left"
    },
    {
      Header: "Payment Status",
      accessor: (a: any) => a ? a?.response?.message?.order?.payment?.status : null,
      sticky: "left"
    },
    {
      Header: "Price",
      // accessor: (item: any) => item ? item?.response?.message?.order?.quote?.breakup[0].price?.value : null,
      accessor: (item: any) => item ? item?.response?.message?.order?.quote?.price?.value : null,
      sticky: "left"
    },
    {
      Header: "Total Order Value",
      accessor: (item: any) => item ? item?.response.message.order.items[0].quantity?.count : null,
      sticky: "left"
    },
    {
      Header: "Delivery City",
      accessor: (a: any) => a?.delivery_city ? a?.delivery_city : null,
      sticky: "left"
    },
    {
      Header: "Delivery Pincode",
      accessor: (item: any) => item ? item?.response?.message?.order?.billing?.address?.area_code : null,
      sticky: "left"
    },
    {
      Header: "Seller NP Order Id",
      accessor: (a: any) => a?.shopifyorderid ? a.shopifyorderid : null,
      sticky: "left"
    },
    {
      Header: "Seller NP Type (MSN / ISN)",
      accessor: (a: any) => a ? `ISN` : null,
      sticky: "left"
    },
    // {
    //   Header: "Order Status",
    //   accessor: (a: any) => a?.status ? a?.status : null,
    //   sticky: "left"
    // },
    {
      id: 'OrderStatusColumn',
      Header: () => (
        <div>
          <select onChange={(e) => selectOrderStatusHandler(e)} style={{ background: "inherit", color: "white", border: "none", width: "125px", textAlign: 'center' }}>
            <option value="" style={{ background: "green", color: "white" }} selected disabled > Order Status</option>
            <option value="Accepted" style={{ background: "green", color: "white" }}  > Accepted</option>
            <option value="In-progress" style={{ background: "green", color: "white" }}  > In-Progress</option>
            <option value="Completed" style={{ background: "green", color: "white" }}  > Completed</option>
            <option value="Cancelled" style={{ background: "green", color: "white" }} > Cancelled</option>
          </select>

        </div>
      ),
      Footer: "Order Status",
      accessor: (a: any) => a?.status ? a?.status : null,
      sticky: "left"
    },
    {
      Header: "Buyer NP Name",
      accessor: (a: any) => a?.response?.context?.bap_id ? a.response?.context?.bap_id : null,
      sticky: "left"
    },
    {
      Header: "Name of Seller",
      accessor: (a: any) => a ? `REDTAPE LIMITED` : null,
      sticky: "left"
    },
    {
      Header: "Network Transaction Id",
      accessor: (item: any) => item?.response?.context?.transaction_id ? item?.response?.context?.transaction_id : null,
      sticky: "left"
    },
    {
      Header: "SKU Name",
      accessor: (item: any) => item ? item?.response?.message?.order?.quote?.breakup[0]?.title : null,
      sticky: "left"
    },
    {
      Header: "SKU Code",
      accessor: (a: any) => a?.sku ? a.sku : null,
      sticky: "left"
    },
    {
      Header: "Order Category (F&B/ Grocery/ Home & Decor)",
      accessor: (a: any) => a ? `Fashion` : null,
      sticky: "left"
    },
    {
      Header: "Logistics Seller NP Name (For on-network delivery)",
      accessor: (a: any) => a ? `Off-Network` : null,
      sticky: "left"
    },
    {
      Header: "Seller City",
      accessor: (a: any) => a ? `Gautam Buddha Nagar` : null,
      sticky: "left"
    },
    {
      Header: "Seller Pincode",
      accessor: (a: any) => a ? `201301` : null,
      sticky: "left"
    },
    {
      Header: "Total Shipping Charges",
      accessor: (a: any) => a ? a?.response?.message?.order?.quote?.breakup?.find(((item: { title: string; }) => item?.title === "Delivery charges") || {})?.price?.value : null,
      sticky: "left"
    },
    {
      Header: "Total Order Value (Inc Shipping charges)",
      accessor: (a: any) => a ? a?.response?.message?.order?.quote?.price?.value : null,
      sticky: "left"
    },
    {
      Header: "Orders Details",
      accessor: (item: any) => (
        <div style={styles?.detailsStyle}>
          <button className="badge badge-info btn-info" data-toggle="modal"
            data-target="#myModal"
            onClick={() => handleClickPop(item?.ondcorderid)}>
            View
          </button>
          <button className="badge badge-outline-dark btn-outline-primary mt-2"
            // onClick={showModal}
            onClick={() => ChangeStatusPopup(item?.ondcorderid, item?.status, item?.response?.context)}
          >
            Update
          </button>
          <button
            className="badge badge-dark btn-success mt-2"
            onClick={() =>
              changeStatusseller(item?.ondcorderid, item?.response?.context)
            }
          >
            Send Status
          </button>

          <button
            className="badge badge-danger btn-danger mt-2"
            data-toggle="modal"
            data-target="#cancelProductModal"
            onClick={() =>
              CancelOrderPopup(item?.ondcorderid)
            }
          >
            Cancel
          </button>
        </div>
      ),
      sticky: "left",
    }
  ]
  function selectOrderStatusHandler(e: any) {
    let allorders = [...orderList];
    let filteredOrders = allorders.filter((item: any) => item?.status === e.target.value);
    setManageFilter([...filteredOrders])
  }

  const POPCOLUMNS = [
    {
      Header: "Sn.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Shopify OrderId",
      accessor: (a: any) => (a?.shopifyorderid ? a?.shopifyorderid : null),
      sticky: "left"
    },
    {
      Header: "ONDC OrderId",
      accessor: (a: any) => (a?.ondcorderid ? a?.ondcorderid : null),
      // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "Item Id",
      accessor: (a: any) => (a?.itemid ? a?.itemid : null),
      // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "F_Code",
      accessor: (a: any) => (a?.f_code ? a?.f_code : null),
      // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "F_Name",
      accessor: (a: any) => (a?.f_name ? a?.f_name : null),
      // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "Cancellation reason /retrun reason(Network description)",
      accessor: (a: any) => (a?.cancellation_reason ? a?.cancellation_reason : null),
      // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "Cancelled At Date & time",
      accessor: (a: any) => (a?.cancel_date ? a?.cancel_date : null),
      // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "Cancelled_by",
      accessor: (a: any) => (a?.cancelled_by ? a?.cancelled_by : null),
      // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "Cancellation_reason_id",
      accessor: (a: any) => (a?.cancellation_reason_id ? a?.cancellation_reason_id : null),
      // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "Status",
      accessor: (a: any) => (a?.status ? a?.status : null),
      // Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "Actions",
      accessor: (item: any) => (
        <div style={styles?.detailsStyle}>
          <button
            className="badge badge-danger btn-danger"
            type="submit"
            onClick={() =>
              Cancel_Item(item?.ondcorderid, item?.itemid)
            }
          >
            Cancel
          </button>
          <button
            className="badge badge-outline-dark btn-outline-danger mt-2"
            onClick={() =>
              changeUpdate_Unsolicated(item?.ondcorderid)
            }
          >
            Unsolicated Update
          </button>
        </div>
      ),
      sticky: "left",
    },
  ]

  // console.log("managefilter", managefilter)

  return (
    <>
      <ThemeMaker>
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4 mt-1">
              <div className="card-header py-3">
                <div className="row d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-danger">Order List</h6>
                  <div>
                    <a href="https://ondc.redtape.com/orders/exportcsv" className="btn btn-danger" >Download Sheet</a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="col-6">
                      <label htmlFor="start-date">Search Filter : </label>
                      <input type="search" name="manage_order" placeholder="Search" className="mr-2 form-control" onChange={(event) => filterBySearch(event)} />
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <div className="col-6">
                      <label htmlFor="start-date"> Start Date : </label>
                      <input type="date" className="form-control" placeholder="Select a date" id="start-date"
                        value={startDate}
                        onChange={handleStartDateChange} />
                    </div>
                    <div className="col-5">
                      <label htmlFor="end-date"> End Date : </label>
                      <input type="date" className="form-control" value={endDate}
                        onChange={handleEndDateChange} id="end-date" />
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="card-body">
                <div className="table-responsive table-bordered">
                  {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    managefilter && <Paginated data={managefilter} columns={COLUMNS} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog ">
              <div className="modal-content container">
                <div className="modal-header">
                  <h6 className="m-0 font-weight-bold text-danger">Orders Details</h6>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="popupColomn">
                  <div className="table-responsive table-striped table-bordered">
                    <div>
                      {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
                          <CircularProgress />
                        </div>
                      ) : (
                        orderPopup && <Paginated data={orderPopup} columns={POPCOLUMNS} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default"
                    data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {orderStatusPopup ? (
          <AntModal
            open={orderStatusPopup}
            onOk={handleOk}
            onCancel={handleCancel}
            width={600}
            footer={null}
          >
            {/* <div className="orderStatuspopup text-center mx-auto p-5 border shadow bg-light position-fixed top-50 end-0 translate-middle-y rounded"> */}
            <h6 className="m-0 font-weight-bold text-danger text-left ">Change Status</h6>
            <hr />
            <div className="text-center">
              <div className="selectStatus">
                <p>{`Update order : ${status}`}</p>
                <select
                  className="btn btn-secondary"
                  value={status}
                  onChange={(event: any) => {
                    setstatus(event.target.value);
                  }}
                >
                  <option value="Created" disabled={dynamicValue !== "Created"}>
                    Created
                  </option>
                  <option
                    value="Accepted"
                    disabled={
                      dynamicValue !== "Created" &&
                      dynamicValue !== "Accepted" &&
                      (dynamicValue === "In-progress" ||
                        dynamicValue === "Completed")
                    }
                  >
                    Accepted
                  </option>
                  <option
                    value="In-progress"
                    disabled={
                      // dynamicValue !== "Created"
                      dynamicValue !== "Accepted" &&
                      (dynamicValue === "In-progress" ||
                        dynamicValue === "Completed")
                    }
                  >
                    In-progress
                  </option>
                  <option
                    value="Completed"
                    disabled={
                      dynamicValue !== "Accepted" &&
                      dynamicValue === "Completed"
                    }
                  >
                    Completed
                  </option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              <div className="selectStatus">
                <p className="m-2">{`Update fulfillment : ${fullFillmentStatus}`}</p>
                <select
                  className="btn btn-secondary"
                  value={fullFillmentStatus}
                  onChange={(event: any) => {
                    setFullFillmentStatus(event.target.value);
                  }}
                >
                  <option value="Pending" disabled={status !== "Accepted"}>
                    Pending
                  </option>
                  <option
                    value="Order-picked-up"
                    disabled={status !== "In-progress"}
                  >
                    Order picked up
                  </option>
                  <option
                    value="Out-for-delivery"
                    disabled={status !== "In-progress"}
                  >
                    Out for delivery
                  </option>
                  <option value="Packed"
                    disabled={status !== "In-progress"}
                  >
                    Packed
                  </option>
                  <option
                    value="Order-delivered"
                    disabled={status !== "Completed"}
                  >
                    Order delivered
                  </option>
                  <option value="Cancelled" disabled={status !== "Cancelled"}>
                    Cancelled
                  </option>
                </select>
              </div>
            </div>
            <hr />
            <div className="row justify-content-around">
              <div className="col-sm-6 text-center ">
                <p className="m-2 badge-light" >{`Update fulfillment : ${timestamp}`}</p>
                <input type="text" className="form-control" placeholder="YYYY-MM-DDThh:mm:ssTZD"
                  value={timestamp}
                  onChange={(event: any) => {
                    setTimestamp(event.target.value);
                  }}
                />
              </div>
            </div>
            {/* <button
              className="btn btn-primary d-flex m-auto updateButton"
              type="submit"
              onClick={changeStatus}
            >
              Update
            </button> */}
            {/* <button
              className="border-0 px-3 py-2 rounded-circle "
              onClick={closePop}
            >
              x
            </button> */}
            {/* </div> */}
            <div className="ant-modal-footer">
              <button
                className="btn btn-primary btn-sm"
                type="submit"
                onClick={async () => { await changeStatus(); handleOk(); }}>
                {updating ? 'Updating...' : 'Update'}
              </button>
            </div>
          </AntModal>
        ) : null}
        {cancelOrderPopup ? (
          // <div className="cancelOrderPopup text-center mx-auto p-5 border shadow bg-light position-fixed top-50 end-0 translate-middle-y">
          <div className="modal fade" id="cancelProductModal" role="dialog">
            <div className="modal-dialog modal-sm w-50" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-center m-0 font-weight-bold text-danger">Cancel Product</h5>
                  <button type="button" ref={closeModel} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="text-center">
                  <div className="selectStatus">
                    <p>{`Select a Reason for cancellation : ${reason_code}`}</p>
                    <select
                      className="btn btn-secondary"
                      value={reason_code}
                      onChange={(event: any) => {
                        setreason_code(event.target.value);
                      }}
                    >
                      <option selected disabled>Select Reason Code</option>
                      <option value="002">One or more items in the Order not available</option>
                      <option value="005">Merchant rejected the order</option>
                      <option value="008">Order not ready for pickup</option>
                      <option value="011">Buyer not found or cannot be contacted</option>
                      <option value="013">Buyer refused to accept delivery</option>
                      <option value="014">Address not found</option>
                      <option value="015">Buyer not available at location</option>
                      <option value="016">Accident / rain / strike / vehicle issues</option>
                      <option value="017">Order delivery delayed or not possible</option>
                      <option value="018">Delivery pin code not serviceable</option>
                      <option value="019">Pickup pin code not serviceable</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-primary btn-sm"
                    type="submit"
                    onClick={cancelOrder}
                  >
                    Update
                  </button>
                  {/* <button
                    className="border-0 px-3 py-2 rounded-circle "
                    onClick={closeCancelPop}
                  >
                    x
                  </button> */}
                  <button type="button" id="closebtn" className="btn btn-secondary btn-sm" data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          // </div>
        ) : null}
      </ThemeMaker>
    </>
  );
};

export default OrderList;
