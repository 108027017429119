export const SizeData = [
    "XXS", "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL", "7XL", "8XL", "9XL", "10XL", "24", "26", "28", "30", "32", "34", "36", "38", "39", "40", "42", "44", "46", "48", "50", "52", "Free Size"
];
export const SizeDataBoyGirl = [
   "0-3 Months","0-6 Months","3-6 Months","6-9 Months","6-12 Months","9-12 Months","12-18 Months","18-24 Months","0-1 Years","1-2 Years","2-3 Years","3-4 Years","4-5 Years","5-6 Years","6-7 Years","7-8 Years","8-9 Years","9-10 Years","10-11 Years","11-12 Years","12-13 Years","13-14 Years","Free Size"
];
export const UOMData = [
    "unit", "dozen", "gram", "kilogram", "tonne", "litre", "millilitre"
];
export const MaterialData = [
    , "aluminium", "brass", "carbon", "cotton", "leather", "glass", "grey", "iron", "jute", "leather", "linen", "nylon", "polycarbonate", "polyester", "polyethylene", "polyvinyl", "PVC", "rayon", "rubber", "sand", "silicon", "silk", "stainless", "steel", "synthetic", "textile", "thermo", "thermoplastic", "water", "wood", "wool", "zinc"
];
// export const MaterialData = [
//    "alpha","acetate","acrylic","acrylonitrile","aluminium","battery","brass","canvas","carbon","cellulose","cork","cotton","elastane","elastodiene","elastolefin","ethylene","EVA","expanded","faux leather","foamed","glass","grey","iron","jute","leather","linen","lycra","lyocell","mesh","modal","nubuck","nylon","polyamide","polycarbonate","polyester","polyethylene","polymethylpentene","polyoxymethylene","polypropylene","polyurethane","polyvinyl","PU","PVC","rayon","rice","rubber","sand","silicon","silk","stainless","steel","styrene","suede","synthetic","textile","thermo","thermoplastic","tritan","velvet","viscose","water","wood","wool","zinc"
// ];

export const ColourData = [
    { name: "Red", value: "#FF0000" },
    { name: "Green", value: "#00FF00" },
    { name: "Yellow", value: "#FFFF00" },
    { name: "Blue", value: "#0000FF" },
    { name: "Pink", value: "#FFC0CB" },
    { name: "Orange", value: "#FF681F" },
    { name: "Gray", value: "#808080" },
    { name: "Sky Blue", value: "#76D7EA" },
    { name: "Brown", value: "#964B00" },
    { name: "Purple", value: "#660099" },
    { name: "Violet", value: "#240A40" },
    { name: "Magenta", value: "#FF00FF" },
    { name: "Black", value: "#000000" },
    { name: "White", value: "#FFFFFF" },
    { name: "silver", value: "#C0C0C0" },
    { name: "gold", value: "#FFD700" },

];

export const FabricData = [
    "acrylic", "bamboo", "cotton", "cotton linen", "cotton silk",
    "denim", "dupion silk", "georgette", "jute", "jute cotton",
    "jute silk", "khadi cotton", "leather", "linen", "mulmul", "modal",
    "net", "nylon", "polycotton", "polyester", "rayon", "shantoon", "silk",
    "synthetic", "tissue", "velvet", "wool"
]

// DELETED SOME FABRICS BECAUSE OF LONG ARRAY , IF REQUIRED PICK FROM BELOW

// export const FabricData = [
//     "alpha", "acrylic", "art silk", "bamboo", "chambray", "chanderi cotton", "chanderi silk",
//     "chiffon", "cotton", "cotton blend", "cotton cambric", "cotton linen", "cotton silk",
//     "crepe", "denim", "dupion silk", "elastane", "georgette", "jacquard", "jute", "jute cotton",
//     "jute silk", "khadi cotton", "kora muslin", "lace", "leather", "linen", "mulmul", "modal",
//     "net", "nylon", "organza", "paper silk", "pashmina", "poly chiffon", "poly crepe", "poly georgette",
//     "poly silk", "polycotton", "polyester", "rayon", "rayon slub", "satin", "shantoon", "silk",
//     "silk blend", "soft silk", "super net", "synthetic", "taffeta silk", "tissue", "tussar silk",
//     "velvet", "vichitra silk", "viscose", "viscose rayon", "voile", "wool"
// ]

export const daysData = [
    { name: "1 Day", value: "P1D" },
    { name: "2 Days", value: "P2D" },
    { name: "3 Days", value: "P3D" },
    { name: "4 Days", value: "P4D" },
    { name: "5 Days", value: "P5D" },
    { name: "6 Days", value: "P6D" },
    { name: "7 Days", value: "P7D" },
    { name: "8 Days", value: "P8D" },
    { name: "9 Days", value: "P9D" },
    { name: "10 Days", value: "P10D" },
]
export const ShoeSizedata = [
    "IND-5", "IND-6", "IND-7", "IND-8", "IND-9", "IND-10", "IND-11", "IND-12", "IND-13",
    "UK1", "UK1.5", "UK2", "UK2.5", "UK3", "UK3.5", "UK4", "UK4.5", "UK5", "UK5.5",
    "UK6", "UK6.5", "UK7", "UK7.5", "UK8", "UK8.5", "UK9", "UK9.5", "UK10", "UK10.5",
    "UK11", "UK11.5", "UK12", "UK12.5", "UK13", "UK13.5"
];


export const FashionproductTypes = [
    { id: '57', name: 'T Shirts' },
    { id: '62', name: 'Dresses' },
    { id: '58', name: 'Watches' },
    { id: '63', name: 'Handbags' },
    { id: '60', name: 'Pants' },
    { id: '61', name: 'Sarees' },
    { id: '67', name: 'Sweatshirts' },
    { id: '68', name: 'Kurtas & Kurta Sets' },
    { id: '69', name: 'Jackets & Coats' },
    { id: '70', name: 'Sweaters' },
    { id: '71', name: 'Suits' },
    { id: '72', name: 'Sherwanis' },
    { id: '73', name: 'Track Shirts' },
    { id: '74', name: 'Track Suits' },
    { id: '75', name: 'Unstitched Fabrics' },
    { id: '76', name: 'Tops' },
    { id: '78', name: 'Trousers' },
    { id: '79', name: 'Capris' },
    { id: '80', name: 'Coordinates' },
    { id: '81', name: 'Playsuits' },
    { id: '82', name: 'Jumpsuits' },
    { id: '83', name: 'Shrugs & Blouses' },
    { id: '84', name: 'Blazers & Waistcoats' },
    { id: '85', name: 'Tights, Leggings & Jeggings' },
    { id: '86', name: 'Jeans' },
    { id: '87', name: 'Shorts' },
    { id: '88', name: 'Joggers' },
    { id: '90', name: 'Churidars' },
    { id: '91', name: 'Salwars' },
    { id: '92', name: 'Dungarees & Jumpsuits' },
    { id: '93', name: 'Skirts' },
    { id: '94', name: 'Clothing Sets' },
    { id: '95', name: 'Belts' },
    { id: '96', name: 'Caps & Hats' },
    { id: '97', name: 'Sarees' },
    { id: '98', name: 'Ethnic Wear' },
    { id: '99', name: 'Palazzos' },
    { id: '100', name: 'Dress Materials' },
    { id: '101', name: 'Lehenga Cholis' },
    { id: '103', name: 'Blouses' },
    { id: '104', name: 'Blouse Pieces' },
    { id: '105', name: 'Briefs' },
    { id: '106', name: 'Boxers' },
    { id: '107', name: 'Vests' },
    { id: '108', name: 'Robes' },
    { id: '109', name: 'Night Suits' },
    { id: '110', name: 'Thermal Wear' },
    { id: '111', name: 'Swim Bottoms' },
    { id: '112', name: 'Swimwear' },
    { id: '114', name: 'Shapewear' },
    { id: '115', name: 'Sleepwear & Loungewear' },
    { id: '116', name: 'Camisoles' },
    { id: '117', name: 'Lingerie Sets & Accessories' },
    { id: '118', name: 'Bath Robes' },
    { id: '119', name: 'Towels' },
    { id: '120', name: 'Pyjamas' },
    { id: '121', name: 'Party Wear' },
    { id: '123', name: 'Nightwear & Loungewear' },
    { id: '124', name: 'Gloves' },
    { id: '125', name: 'Socks' },
    { id: '126', name: 'Stockings' },
    { id: '127', name: 'Laces' },
    { id: '128', name: 'Soles & Charms' },
    { id: '129', name: 'Shoe Racks & Organisers' },
    { id: '130', name: 'Shoe Care - Accessories' },
    { id: '64', name: 'Sandals & Floaters' },
    { id: '145', name: 'Sunglasses' },
    { id: '146', name: 'Contact Lens Cases' },
    { id: '65', name: 'Shirts' },
    { id: '59', name: 'Track Pants' },
    { id: '89', name: 'Dhotis & Dhoti Pants' },
    { id: '102', name: 'Burqas & Hijabs' },
    { id: '113', name: 'Bra' },
    { id: '122', name: 'Innerwear & Sleepwear' },
    { id: '131', name: 'Flip-Flops & Flats' },
    { id: '132', name: 'Backpacks' },
    { id: '133', name: 'Trolley, Luggage & Suitcases' },
    { id: '134', name: 'Formal Shoes' },
    { id: '135', name: 'Casual Shoes' },
    { id: '136', name: 'Sports Shoes' },
    { id: '137', name: 'Outdoor Shoes' },
    { id: '138', name: 'Work & Safety Shoes' },
    { id: '139', name: 'Ethnic Shoes' },
    { id: '140', name: 'Boots' },
    { id: '141', name: 'Heels' },
    { id: '142', name: 'Contact Lenses' },
    { id: '143', name: 'Eye Glasses' },
    { id: '144', name: 'Eye Glass Frames' },
    { id: '147', name: 'Contact Lens Solutions' },
    { id: '148', name: 'Contact Lens Tweezers' },
    { id: '149', name: 'Eyeglasses Pouches & Cases' },
    { id: '150', name: 'Microfiber Wipes' },
    { id: '151', name: 'Eyewear Slings' },
    { id: '152', name: 'Bracelets' },
    { id: '153', name: 'Chains' },
    { id: '154', name: 'Mangalsutra' },
    { id: '155', name: 'Anklets' },
    { id: '156', name: 'Bangles & Bracelets' },
    { id: '157', name: 'Necklaces' },
    { id: '158', name: 'Earrings' },
    { id: '159', name: 'Jewellery Sets' },
    { id: '160', name: 'Nosepins & Noserings' },
    { id: '161', name: 'Pendants' },
    { id: '162', name: 'Rings' },
    { id: '163', name: 'Toe Rings' },
    { id: '164', name: 'Gold Coins' },
    { id: '166', name: 'Brooch' },
];