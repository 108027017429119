/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
// import styles from "../transaction/transaction.module.css"
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import React, { useState, useEffect, memo } from "react";
import { tokenDecode } from "../../utils/helper";
import { config } from "../../utils/config";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import styled from "styled-components"
import Variants from "../../components/Variants/Variants";
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Papa from 'papaparse';
import ImageAdd from '../../components/ImageAdd/ImageAdd';
import ThemeMaker from "../../components/thememaker";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { FashionproductTypes } from "../../utils/rawdata"
// import * as XLSX from 'xlsx';
// import * as excelJs from 'exceljs';
import CircularProgress from '@mui/material/CircularProgress';
const { apiEndpoint, awsKeyId, awsSecretKey, awsRegion, awsBucket } = config;
const aws = require("aws-sdk");
const smstyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "80%", bgcolor: 'background.paper', boxShadow: 24, borderRadius: "10px", p: 1, maxHeight: 600, overflow: "scroll", color: "black",
};
const Sstyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: "10px", bgcolor: 'background.paper', border: '1px solid #000', boxShadow: 24, p: 2,
};

const Addproduct = () => {
  const decode = tokenDecode();
  const [brandlist, setbrandlist] = useState([]);
  const [sampleCSVdata, setSampleCSV] = useState({ category: "", product_type: "", sub_category: "", product_type_array: [], gender_specification: "" })
  const [mopen, setmopen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [iopen, setiopen] = React.useState(false);
  const [featureopen, setfeatureopen] = React.useState(false);
  const [categoryOption, setCategoryOption] = React.useState("");
  const handleOpen = () => setmopen(true);
  const handleImageOpen = () => setiopen(true);
  const handleFeaturesOpen = () => setfeatureopen(true);
  const handleClose = () => setmopen(false);
  const handleimageClose = () => setiopen(false);
  const handleFeaturesClose = () => setfeatureopen(false);


  const returnWindowLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  const timeToShipLength = new Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
  const [taxData, setTaxData] = useState([]);
  const [readOnly, setreadOnly] = useState(false);
  const rawData = {
    name: "",
    pc_manufacturer_or_packer_name: "",
    quantity: 0,
    short_description: "",
    description: "",
    // categories_id: Subcategorydata.parent_id,
    mrp: 0,
    selling_price: 0,
    tax_included_price: 0,
    tax: 0,
    tags: "",
    image: [],
    sku: "",
    barcode: "",
    position: 1,
    // status: true,
    returnable: "",
    cancelable: "",
    return_window: "",
    seller_pickup_return: "",
    time_to_ship: "",
    cod: "",
    pc_imported_product_country_of_origin: "",
    tax_included: true,
    country_of_origin: "",
    uom: "",
    customer_care_contact: "",
    replaceable: "",
    brand: "Redtape",
    category_id: "Fashion",
    product_type_name: "",
    pc_common_or_generic_name_of_commodity: "",
    pc_manufacturer_or_packer_address: "",
    pc_month_year_of_manufacture_packing_import: "",
    has_variations: false,
    parent_id: "",
    weight: "",
    features: "",
    gender: "",
    fabric: ""
  };
  const rawDataError = {
    pc_month_year_of_manufacture_packing_import: false,
    pc_common_or_generic_name_of_commodity: false,
    pc_manufacturer_or_packer_address: false,
    product_type_name: false,
    category_id: false,
    name: false,
    short_description: false,
    description: false,
    categories_id: false,
    net_qty: false,
    pc_manufacturer_or_packer_name: false,
    quantity: false,
    mrp: false,
    selling_price: false,
    tax_included_price: false,
    tax: false,
    customer_care_contact: false,
    tags: false,
    image: false,
    sku: false,
    barcode: false,
    position: false,
    // status: false,
    food_type_veg: false,
    returnable: false,
    cancelable: false,
    return_window: false,
    seller_pickup_return: false,
    time_to_ship: false,
    cod: false,
    product_type: false,
    preservatives: false,
    preservatives_details: false,
    flavours_and_spices: false,
    ready_to_cook: false,
    ready_to_eat: false,
    rice_grain_type: false,
    recommended_age: false,
    baby_weight: false,
    absorption_duration: false,
    scented_or_flavour: false,
    herbal_or_ayurvedic: false,
    theme_or_occasion_type: false,
    hair_type: false,
    mineral_source: false,
    caffeine_content: false,
    capacity: false,
    pf_brand_owner_FSSAI_license_no: false,
    tax_included: false,
    country_of_origin: false,
    uom: false,
    replaceable: false,
    brand: false,
    has_variations: false,
    parent_id: false,
    weight: false,
    pc_imported_product_country_of_origin: false

  };
  const [file, setFile] = useState(null);
  const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
  const [downloadCSVModalOpen, setdownloadCSVModalOpen] = useState(false);
  const [productTypes, setproductTypes] = useState([]);
  const [product, setProduct] = useState(rawData);
  console.log("productstate----------->", product)
  const [productErr, setProductErr] = useState(rawDataError);
  // const [allDynamicField, setallDynamicFiled] = useState({
  //   gender: "",
  //   size_chart: "",
  //   fabric: ""
  // })
  const [categoryList, setCategoryList] = useState([]);
  const [productTypeName, setproductTypeName] = useState("")
  let params = useParams();
  const { id } = params;


  const [productIdVariantsData, setproductIdVariantsData] = useState([])
  useEffect(() => {
    taxListHanler();
    if (id) {
      getProductDetails()
    }
  }, []);

  async function getProductDetails() {
    let apiEndpoint = baseUrlDefiner();
    const { data } = await axios({
      url: `https://ondc.redtape.com/product/getproductsbyId`,
      method: "POST",
      data: {
        "parent_id": id
      }
    });

    setProduct({ ...data, has_variations: true, name: data?.title, product_type_name: data?.category_id })

    console.log("product detiails----", data)

  }




  async function getadditionaldetails() {
    let apiEndpoint = baseUrlDefiner();
    const { data } = await axios({
      url: `${apiEndpoint}/sellerShop`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
      },

    });

    if (data.status === 200) {
      let adddata = data.response;
      setProduct({
        ...product, cod: adddata.cod, time_to_ship: adddata.time_to_ship, replaceable: adddata.replaceable, returnable: adddata.returnable, cancelable: adddata.cancelable, return_window: adddata.return_window,
        seller_pickup_return: adddata.seller_pickup_return
      })
    }
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const hasVariants = "true";


  const getProduct = async () => {
    let apiEndpoint = baseUrlDefiner();
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/getVarNonvarpro`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: {
          "has_variation": hasVariants,
          "id": id
        },
      });

      if (data.status === 200) {

        if (hasVariants == "false") {
          let imageArray = data.data[0].image.split(",");
          setProduct({ ...data.data[0], image: imageArray });
        } else {
          setProduct({ ...data.data[0] });
        }

        setreadOnly(true)
      }
    } catch (error) { }
  };

  const [isVariantsLoading, setisVariantsLoading] = useState(false);

  async function getVariantsData() {
    setisVariantsLoading(true);
    setproductIdVariantsData(product?.line_items);
    handleOpen();
    setisVariantsLoading(false);

  }

  async function restoreData() {
    setisVariantsLoading(true)
    try {

      setproductIdVariantsData(product?.line_items);
      handleOpen();

      setisVariantsLoading(false)

    } catch (error) {
      setisVariantsLoading(false)
    }
  }

  //-----------------------  THIS THE NEW FNCTION TO IMPORT THE PRODUCT--------------------------------------------
  const fullUrl = window.location.host;

  function handleShipementModalClose() {
    setshipmentModalOpen(false)
  }

  const errorHandler = (e) => {
    if (e.target.value) {
      setProductErr({ ...productErr, [e.target.name]: false });
    } else {
      setProductErr({ ...productErr, [e.target.name]: true });
    }
  };

  const taxListHanler = async () => {
    let apiEndpoint = baseUrlDefiner();
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/gettaxlist`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
      });
      // console.log("tax listing", data);
      if (data.status === 200) {
        setTaxData(data.data);
      }
    } catch (error) { }
  };

  const priceHandler = (e) => {
    // console.log("e.target.name", e.target.name);
    if (e.target.name === "selling_price") {
      const percentAmmount =
        (parseFloat(product.tax) / 100) * parseFloat(e.target.value);
      setProduct((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: product?.tax_included
          ? e.target.value
          : parseFloat(e.target.value) + parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax") {
      const percentAmmount =
        (parseFloat(e.target.value) / 100) * parseFloat(product.selling_price);
      setProduct((preState) => ({
        ...preState,
        [e.target.name]: parseFloat(e.target.value),
        tax_included_price: product?.tax_included
          ? product?.tax_included_price
          : parseFloat(product.selling_price) +
          parseFloat(percentAmmount.toFixed(4)),
      }));
    } else if (e.target.name === "tax_included") {
      const percentAmmount =
        (parseFloat(product.tax) / 100) * parseFloat(product.selling_price);
      setProduct({
        ...product,
        [e.target.name]: !product?.tax_included,
        tax_included_price: !product?.tax_included
          ? product.selling_price
          : percentAmmount + parseFloat(product.selling_price),
      });
    }
  };

  const productImageHandler = async (e, index) => {

    try {
      const files = e.target.files;
      const uploadPromises = Array.from(files).map(async (file) => {
        let myFile = file.name.split(".");
        let myFileType = myFile[myFile.length - 1];

        const s3 = new aws.S3({
          accessKeyId: awsKeyId,
          secretAccessKey: awsSecretKey,
          region: awsRegion
        });

        const params = {
          Bucket: "ondc-marketplace",
          Key: `images/${uuidv4()}.${myFileType}`,
          Body: file,
          ContentType: file.type
        };

        let result = await s3.upload(params).promise();
        return result.Location;
      });

      const locations = await Promise.all(uploadPromises);
      console.log("locations----------->", locations)
      // Update state with all image locations
      setProduct((prevProduct) => ({
        ...prevProduct,
        image: [...prevProduct.image, ...locations]
      }));
    } catch (exception) {
      toast.error("Error in Adding Product Image");
      console.log(exception);
    }
  };


  const handleInput = async (e, operation) => {
    let apiEndpoint = baseUrlDefiner();
    e.preventDefault();
    const showErrorToast = (fieldName) => {
      toast.error(`Please Enter '${fieldName}'!`);
    };

    const validateFields = () => {
      for (const field in product) {
        if (field !== "features" && field !== "parent_id" && field !== "barcode" && field !== "quantity" && field !== "sku" && field !== "uom" && field !== "weight") {
          if (product.hasOwnProperty(field) && product[field] === "") {
            showErrorToast(field);
            return false;
          }
        }

      }
      return true;
    };

    if (product.has_variations == false) {
      if (!product.hasOwnProperty("image") || product.image.length === 0) {
        toast.error("Please select Images!");
        return false;
      }
    }

    console.log(" product.line_items-------", product.line_items)
    if (product?.has_variations && product?.line_items === undefined) {
      toast.error("Please Add Variants for the product!");
      return
    }
    let endpoint;
    if (operation === "create") {
      if (product.has_variations.toString() === "true") {
        endpoint = "create"
      } else {
        endpoint = "create"
      }
    }
    if (operation === "update") {   
        endpoint = "updateproducts"      
    }
    let createProduct = true
    if (createProduct) {
      try {

        const options = {
          method: operation === "create" ? "POST" : "PUT",
          url: `https://ondc.redtape.com/product/${endpoint}`,
          // url: `https://ondc.redtape.com/product/create`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: { ...product, title: product?.name, inventory_quantity: product?.quantity, compareprice: product.mrp, price: product.selling_price },
        };
        const data = await axios(options);
        if (data.status === 200) {
          setProduct(product);
          if (operation === "update") {
            toast.success("Product Updated successfully !");
          } else {
            toast.success("Product Created successfully !");
          }

        }
      } catch (error) {
        console.log(error);
        toast.error("Server error, please try later !");
      }
    } else {
      showErrorToast("all required fields");
    }

  };
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };
  async function submitFun(tableData, variantType, operation) {
    let line_items = tableData?.map((item) => ({ ...item, variant_group: item.variant_group }))
    setProduct({ ...product, line_items })
    toast.success("Variations Added!!")
  }


  function removeImageHandler(index, removeImageHandler) {
    // alert(index);
    let z = [...product.image]
    z.splice(index, 1)
    setProduct({ ...product, image: [...z] })
  }

  async function upDateVariantsHandler(tableData, variatType) {
    try {
      console.log(tableData)
      let line_items = tableData?.map((item) => ({ ...item, variant_group: item.variant_group }))

      let payload = { line_items: line_items, product_id: id, has_variations: true }

      console.log("payload to update variants-------->", payload)
      const  data  = await axios({
        url: `https://ondc.redtape.com/product/updatLineitems`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        method: "PUT",
        data: payload

      })
      if (data.status === 200) {
        toast.success("Variants Updated")
      } else {
        toast.error("Something Went Wrong!")
      }

    } catch (err) {
      console.log(err)
      toast.error("Something Went Wrong!")
    }
  }


  const [optionValues, setoptionValues] = useState([0]);
  const [featureKeyValue, setfeatureKeyValue] = useState([{ code: "", value: "" }])

  function addHandler() {
    let x = [...optionValues, optionValues[optionValues.length - 1] + 1];
    setoptionValues((count) => [...count, count[count.length - 1] + 1]);

    setfeatureKeyValue((item) => [...item, { code: "", value: "" }])
  }

  function featureChangeHandler(index, e) {

    let z = [...featureKeyValue];
    z[index] = { ...z[index], [e.target.name]: e.target.value };
    setfeatureKeyValue([...z])
  }

  function onFeatureSaveHandler() {

    setProduct((prev) => {
      return { ...prev, features: JSON.stringify(featureKeyValue) }
    })

    toast.success("Feature Added! Please update the Product to save.")
  }


  const SizeChartImageHandler = async (e) => {

    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: "AKIAYJUL5VJOBYQMJDT7",
        secretAccessKey: "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"
      })
      const params = {
        Bucket: "ondc-marketplace",
        Key: `images/${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type
      }
      let result = await s3.upload(params).promise();
      const { Location } = result;

    } catch (exception) {
      toast.error("Error in Adding Image")
      console.log(exception);
    }
  };




  async function getProductTypeOnCategory(id) {
    try {
      setLoading(true)
      const { data } = await axios({
        url: `${apiEndpoint}/getProductTypeid`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          product_category_id: parseInt(id)
        }
      })
      if (data && (data.status === 200 || data.status === "200" || Number(data.status) === 200)) {
        setproductTypes(data?.data)
      } else {
        toast.error("Error In Loading Product Type");

      }
      // if (data?.status == "200") {
      //   setSampleCSV((prev) => {
      //     return { ...prev, product_type_array: data?.data }
      //   })
      // }
      setLoading(false)
    } catch (err) {
      toast.error("Server not working, please try later!")
      console.log(err)
    }

  }


  return (
    <ThemeMaker>
      <div className="row w-100 m-0 mt-1">
        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">
                Create Product
                {/* <button onClick={() => generateTemplate()}>Download </button> */}
              </h6>
            </div>

            <>
              <div className="card-body">
                <div className="row bg-white">
                  <div className="col-md-12 pl-4 pr-4">
                    <div className="d-flex justify-content-end align-items-center my-1">
                      <span className="text-center d-none" style={{ background: "lightgrey", borderRadius: "12px", fontSize: "14px", width: "150px", color: "black", fontWeight: "600", height: "27px", cursor: "pointer", paddingTop: "3px" }}
                        onClick={() => { setshipmentModalOpen(true) }}  >Import Products</span>
                    </div>

                    <div className='row pt-2'>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Product Name </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.name ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control" id="" name="name" value={product.name} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })} onBlur={(e) => errorHandler(e)} placeholder="Enter Product Name"
                          />
                        </div>
                      </div>


                      <div className="col-md-4 col-sm-12">
                        <div className="form-group">
                          <div className='d-flex justify-content-between align-item-center'>
                            <div>
                              <label className=" genericlabel">Product Type</label>
                              <span className="text-danger" > * </span>
                            </div>
                            {productErr?.product_type ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                            }
                          </div>
                          <select name="product_type_name" id="" className='form-control' value={product.product_type_name}
                            onChange={(e) => {
                              setProduct({
                                ...product, [e.target.name]: (e.target.value),
                              })}}onBlur={(e) => errorHandler(e)}>
                            <option value="" disabled selected>Select Product Type</option>
                            {FashionproductTypes?.map(item => {
                              return <option value={item.id}>{item.name}</option>
                            })}
                          </select>
                        </div>
                      </div>


                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'> Variants Available</label>
                          <span className="text-danger" > * </span>
                          {(product.has_variations && hasVariants == null) ? <span onClick={() => { restoreData() }} className='modalTrigerButton' >Add Variant</span> : null}
                          {(product.has_variations && hasVariants === "true") ? <span disabled={isVariantsLoading}
                            onClick={() => { getVariantsData() }} className='modalTrigerButton'>{isVariantsLoading ? "Loading..." : "Edit Variants"}</span> : null}

                          {!product.has_variations ?
                            <span className='modalTrigerButton' onClick={handleImageOpen} >Add Images
                            </span> : null}


                          {/* {<span className='modalTrigerButton ml-2' onClick={handleFeaturesOpen} >Add Features</span>} */}

                          {
                            productErr?.has_variations ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <select name="has_variations" className="form-control" value={product.has_variations}
                            onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value === "true" })
                            }
                            onBlur={(e) => errorHandler(e)}>
                            <option value={"true"}>Yes</option>
                            <option value={"false"}>No</option>
                          </select>
                        </div>
                      </div>


                      {product.has_variations == false && <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Product Weight</label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.weight ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control" id=""
                            onKeyPress={preventMinus} name="weight" value={product.weight} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })} placeholder="Enter Weight" onBlur={(e) => errorHandler(e)}

                          />
                        </div>
                      </div>}


                      {product.has_variations == false && <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Unit Of Measurement (UOM) </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.uom ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }


                          <select name="uom" id="" className='form-control' value={product.uom}
                            onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })} onBlur={(e) => errorHandler(e)}  >
                            <option value="" disabled selected>Select UOM</option>
                            <option value="dozen">Dozen</option>
                            <option value="gram">Gram</option>
                            <option value="kilogram">Kilogram</option>
                            <option value="tonne">Ton</option>
                            <option value="litre">Litre</option>
                            <option value="millilitre">Millilitre</option>
                            <option value="unit">Unit</option>
                          </select>
                        </div>
                      </div>}

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Common or Generic Product Name </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.pc_common_or_generic_name_of_commodity ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control" id="" name="pc_common_or_generic_name_of_commodity" value={product.pc_common_or_generic_name_of_commodity} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })} placeholder="Enter Common or Generic Product Name" onBlur={(e) => errorHandler(e)}

                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Gender </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.gender ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }

                          <select name="gender" id="" className='form-control' value={product.gender} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })}>
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Fabric </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.fabric ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control" id="" name="fabric" value={product.fabric} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })} placeholder="Enter Fabric" onBlur={(e) => errorHandler(e)}

                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Size Chart </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.size_chart ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control" id="" name="size_chart" value={product.size_chart} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })} placeholder="Enter Size Chart" onBlur={(e) => errorHandler(e)}

                          />
                        </div>
                      </div>




                      {product.has_variations == false && <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>SKU </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.sku ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control" id=""
                            name="sku" value={product.sku} onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value, })}
                            placeholder="Enter SKU" onBlur={(e) => errorHandler(e)}

                          />
                        </div>
                      </div>}

                      {product.has_variations == false ? <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Selling price  </label>
                          <span className="text-danger" > * </span>

                          <small> &nbsp; Tax included</small>
                          <input type="checkbox" name="tax_included" style={{ marginLeft: "10px" }} onChange={(e) => priceHandler(e)} checked={product?.tax_included} />

                          {
                            productErr?.selling_price ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="number" className="form-control" min={0} name="selling_price" placeholder="Selling Price" value={product.selling_price} onChange={(e) => priceHandler(e)} onBlur={(e) => errorHandler(e)} onWheel={(e) => e.target.blur()}
                          />
                        </div>
                      </div> : null}

                      {product.has_variations == false ? <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Tax included price</label>


                          <input type="number" className="form-control"
                            disabled
                            name="tax_included_price"
                            placeholder=""
                            value={product.tax_included_price}
                            onWheel={(e) => e.target.blur()}

                          />
                        </div>
                      </div> : null}

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Tax ( % ) </label>
                          {/* <span className="text-danger" > * </span> */}
                          {product.has_variations == true ? <> <small> &nbsp; Tax included</small>
                            <input type="checkbox" name="tax_included" style={{ marginLeft: "10px" }} onChange={(e) => priceHandler(e)} checked={product?.tax_included}
                            /></> : null}
                          {
                            productErr?.tax ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <select className="form-control" name="tax"
                            placeholder="Tax" value={product.tax} onChange={(e) => priceHandler(e)} onBlur={(e) => errorHandler(e)} >
                            <option value="">Select tax</option>
                            {taxData.map((item) => (
                              <option value={item?.tax_rate} key={item.id}>{item?.tax_rate}</option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {product.has_variations == false ? <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>To Show Price (MRP) </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.mrp ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="number" className="form-control" onKeyPress={preventMinus} min={0} name="mrp" placeholder="Enter Max price" value={product.mrp ? product.mrp : product.price} onChange={(e) => setProduct({
                            ...product,
                            [e.target.name]: e.target.value,
                          })
                          }
                            onBlur={(e) => errorHandler(e)}
                            onWheel={(e) => e.target.blur()}
                          />
                        </div>
                      </div> : null}


                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Product Manufacturer Name </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.pc_manufacturer_or_packer_name ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control"
                            id="" name="pc_manufacturer_or_packer_name" value={product.pc_manufacturer_or_packer_name} onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value })
                            } onBlur={(e) => errorHandler(e)} placeholder="Enter Manufacturer Name"

                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Product Manufacturer Address </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.pc_manufacturer_or_packer_address ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control" id=""
                            name="pc_manufacturer_or_packer_address"
                            value={product.pc_manufacturer_or_packer_address}
                            onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value })
                            }
                            onBlur={(e) => errorHandler(e)}
                            placeholder="Enter Manufacturer Address"

                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Product Manufacturing Year</label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.pc_month_year_of_manufacture_packing_import ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control" name="pc_month_year_of_manufacture_packing_import" value={product.pc_month_year_of_manufacture_packing_import} onChange={(e) =>
                            setProduct({ ...product, [e.target.name]: e.target.value })}
                            onBlur={(e) => errorHandler(e)} placeholder="Enter Manufacturing Year"

                          />
                        </div>
                      </div>

                      {product.has_variations == false && <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Inventory</label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.quantity ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control" name="quantity" value={product.quantity} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })} onBlur={(e) => errorHandler(e)} placeholder="Enter Quantity"

                          />
                        </div>
                      </div>}

                      {product.has_variations == false && <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Barcode Number</label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.barcode ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text"
                            className="form-control" name="barcode"
                            value={product.barcode}
                            onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value })
                            }
                            onBlur={(e) => errorHandler(e)}
                            placeholder="Enter barcode Number"

                          />
                        </div>
                      </div>}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Tags </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.tags ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text"
                            className="form-control"
                            name="tags"
                            value={product.tags}
                            onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value })
                            }
                            onBlur={(e) => errorHandler(e)}
                            placeholder="Enter Search Tags"

                          />
                        </div>
                      </div>
                     

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Product Long Description </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.description ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control"
                            name="description"
                            value={product.description}
                            onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value })
                            }
                            onBlur={(e) => errorHandler(e)}
                            placeholder="Enter Long Product Description"

                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Country of Origin </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.country_of_origin ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <input type="text" className="form-control"
                            name="country_of_origin"
                            value={product.country_of_origin}
                            onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value })
                            }
                            onBlur={(e) => errorHandler(e)}
                            placeholder="Enter Country of Origin"

                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Time To Ship </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.time_to_ship ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <select

                            className="form-control"
                            name="time_to_ship"
                            value={product.time_to_ship}
                            onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value })
                            }
                            onBlur={(e) => errorHandler(e)}
                          >
                            <option value="">Time to Ship</option>
                            {timeToShipLength.map((item, index) => (
                              <option value={`P${item}D`} key={index}>{item === 1 ? `${item}Day` : `${item}Days`}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Product Returnable </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.returnable ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <select
                            className="form-control"
                            name="returnable"
                            value={product.returnable}
                            onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value })
                            }
                            onBlur={(e) => errorHandler(e)}
                          >
                            <option value={""}>Product Returnable</option>
                            <option value={"TRUE"}>Yes</option>
                            <option value={"FALSE"}>No</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'> Product Return Window </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.return_window ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <select className="form-control" name="return_window" value={product.return_window}
                            onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value })
                            }
                            onBlur={(e) => errorHandler(e)}
                          >
                            <option value="">Select Return Window</option>
                            {returnWindowLength.map((item, index) => (
                              <option value={`P${item}D`} key={index}>{item == 1 ? `${item}Day` : `${item}Days`}</option>
                            ))}
                            <option value={`FALSE`} >False</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Product Cancellable </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.cancelable ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <select className="form-control" name="cancelable" value={product.cancelable}
                            onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value })
                            } onBlur={(e) => errorHandler(e)}  >
                            <option value={""}>Select Cancellable</option>
                            <option value={"TRUE"}>Yes</option>
                            <option value={"FALSE"}>No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'> Return Pickup Available </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.seller_pickup_return ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <select className="form-control" name="seller_pickup_return" value={product.seller_pickup_return}
                            onChange={(e) =>
                              setProduct({ ...product, [e.target.name]: e.target.value })
                            } onBlur={(e) => errorHandler(e)}             >
                            <option value={""}>Select Return Pickup Available</option>
                            <option value={"true"}>Yes</option>
                            <option value={"false"}>No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'> Product Replaceable </label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.replaceable ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <select className="form-control" name="replaceable" value={product.replaceable}
                            onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })} onBlur={(e) => errorHandler(e)}>
                            <option value={""}>Select Product Replaceable</option>
                            <option value={"TRUE"}>Yes</option>
                            <option value={"FALSE"}>No</option>
                          </select>
                        </div>
                      </div>


                       <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Parent ID</label>
                          {/* <span className="text-danger" > * </span>
                          {
                            productErr?.parent_id ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          } */}
                          <input type="number" className="form-control" id=""
                            onKeyPress={preventMinus}
                            name="parent_id" value={product.parent_id}
                            onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value, })}
                            placeholder="Enter a Unique Reference ID"
                            onBlur={(e) => errorHandler(e)}
                            onWheel={(e) => e.target.blur()}

                          />
                          <small className='text-danger'>Should be unique and numerical</small>
                        </div>
                      </div>

                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label className='genericlabel'>Product Status</label>
                          <span className="text-danger" > * </span>
                          {
                            productErr?.status ? (
                              <span className="text-danger tiny_size" > This field is required ! </span>
                            ) : null
                          }
                          <select name="status" className="form-control" value={product.status} onChange={(e) => setProduct({ ...product, [e.target.name]: e.target.value })} onBlur={(e) => errorHandler(e)}>
                            <option value={""}>Select Sell on ONDC</option>
                            <option value={"active"}>Active</option>
                            <option value={"draft"}>Draft</option>
                          </select>
                        </div>
                      </div> */}



                      <div className='col-md-12 d-flex justify-content-end'>




                        <div className="m-2">
                          {id === undefined ? <button className="btn btn-primary" onClick={(e) => { handleInput(e, "create") }}>
                            Create Product
                          </button> : <button className="btn btn-success" onClick={(e) => { handleInput(e, "update") }}>
                            Update
                          </button>}

                        </div>

                      </div>

                      <Modal
                        open={mopen}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >

                        <Box sx={smstyle} className="variantBox">
                          <CloseIcon style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} onClick={handleClose} />
                          <p className="text-center" style={{ fontSize: "20px", fontWeight: "700" }}>Variants</p>


                          <div >
                            <Variants submitFun={submitFun} productIdVariantsData={productIdVariantsData} hasVariants={hasVariants} product={product} upDateVariantsHandler={upDateVariantsHandler} productTypeNmae={productTypeName} id={id} />
                          </div>
                        </Box>
                      </Modal>

                      <Modal
                        open={iopen}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >

                        <Box sx={smstyle} className="variantBox">
                          <CloseIcon style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} onClick={handleimageClose} />
                          <p className="" style={{ fontSize: "20px", fontWeight: "700" }}> Images</p>


                          <div>
                            <ImageAdd productImageHandler={productImageHandler} productData={product} imageIndex={1} removeImageHandler={removeImageHandler} />
                          </div>
                        </Box>
                      </Modal>


                    </div>
                  </div>
                </div>


              </div>
            </>
          </div>
        </div>
      </div>
    </ThemeMaker >
  );
};

export default Addproduct;
