/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useRef, CSSProperties } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import config from "../../config/config"


import ThemeMaker from "../../components/thememaker";
import { CircularProgress } from "@mui/material";
import { Paginated } from "../../components/Paginated";
var $ = require('jquery');

const styles = {
    productImgStyle: {
        width: "50%",
        height: "50px",
        transition: "transform 0.3s ease-in-out",
    } as CSSProperties,
    priceStyle: {
        whiteSpace: "nowrap"
    } as CSSProperties,
};

const ProductList = () => {
    const { baseUrl } = config;
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { product } = decoded.data.permission;
    const history = useHistory();
    const [productList, setProductList] = useState([]);
    const [SearchList, setSearchList] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loading, setloading] = useState(true);
    const closeModel: any = useRef();
    const [isZoomed, setIsZoomed] = useState(false);
    // filter
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    //   const [filteredData, setFilteredData] = useState([]);

    const [error, setError] = useState('');
    const [_id, setId] = useState("");

    const getProductList = async () => {
        try {
            const { data } = await axios({
                url: `${baseUrl}/product`,
                method: "POST",
                headers: {
                    Authorization: token
                },
            });
            console.log("product list------", data)
            setProductList(data);
            setSearchList(data);
            setloading(false);
            // setTimeout(() => {
            //     $('#dataTable').DataTable();
            // }, 1000)
        } catch (error) {
            setloading(false);
            setError("error");
        }
    }

    const handleMouseEnter = () => {
        setIsZoomed(true);
    };

    const handleMouseLeave = () => {
        setIsZoomed(false);
    };
    const priceStyle = {
        whiteSpace: "nowrap",
    }
    const deleteProduct = async () => {
        try {
            setDeleteLoading(true);
            await axios({
                url: `${baseUrl}/product/delete`,
                method: "DELETE",
                headers: {
                    Authorization: ` ${token}`
                },
                data: { _id }
            });
            setDeleteLoading(false);
            setProductList(productList.filter((itm: any) => itm._id !== _id));
            getProductList();
            closeModel.current.click();
        } catch (error) {
            setDeleteLoading(false);
        }
    }
    const redirectToUserPage = (_id: any) => {
        // const product = productList.find((item: any) => item._id === _id);
        // localStorage.setItem('product', JSON.stringify(product));
        history.push(`/product/${_id}`);
    }
    // UseEffect
    useEffect(() => {
        getProductList();
    }, [])

    const handleStartDateChange = (event: any) => {
        setStartDate(event.target.value);
        filterData(event.target.value, endDate);
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event.target.value);
        filterData(startDate, event.target.value);
    };
    // filter By Dates
    const filterData = (start: any, end: any) => {
        const startFilter = start ? new Date(start) : null;
        const endFilter = end ? new Date(end) : null;
        const filtered = productList.filter((item: any) => {
            const itemDate = new Date(item.createdAt);
            return (
                (!startFilter || itemDate >= startFilter) && (!endFilter || itemDate <= endFilter)
            );
        });
        setSearchList(filtered);
    };

    // FIlter Search
    const filterBySearch = (event: any) => {
        const querySearch = event.target.value;
        // const querySearch = event.target.value.toLowerCase();
        let SearchFiltere = productList.filter((item: any) => {
            const priceAsString = String(item?.price);
            const lowerCasedQuery = querySearch.toLowerCase();
            return (
                item?.title?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                item?.status?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                item?.vendor?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                priceAsString.includes(lowerCasedQuery)
            );
        });
        setSearchList(SearchFiltere);
    };

    const exportToCSV = (data_s: any) => {
        const escapeCSVField = (field :any) => {
            if (typeof field === 'string') {
                field = field.replace(/"/g, '""');
                if (field.includes(',') || field.includes('"') || field.includes('\n')) {
                    return `"${field}"`;
                }
            }
            return field || ''; 
        };
        console.log("data_s", data_s)
        const headers = [
            'S.No',
            'Brand',
            'Title',
            'Price',
            'SKU',
            'Quantity',
            'Selling Price',
            'Uom',
            'Category',
            'Description',
            'Tags',
            'Fabric',
            'Images',
        ];
         
        const data = data_s.map((item: any, i: any) => {
            return [
                i + 1,
                escapeCSVField(item?.brand),           
                escapeCSVField(item?.title),           
                item?.line_items[0]?.mrp || '',        
                item?.line_items[0]?.sku || '',        
                item?.line_items[0]?.quantity || '',        
                item?.line_items[0]?.selling_price || '',        
                item?.line_items[0]?.uom || '',        
                item?.category_id || '',               
                escapeCSVField(item?.description),     
                escapeCSVField(item?.tags),            
                escapeCSVField(item?.fabric),          
                escapeCSVField(item?.line_items[0]?.image[0]), 
            ].join(',');
        });
        const csvContent = [headers.join(','), ...data].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `products_${new Date().getTime()}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
    }



    const COLUMNS = [
        {
            Header: "Sn.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: false,
            sticky: "left"
        },
        {

            Header: "Images",
            accessor: (item: any) => (<img
                style={isZoomed ? { ...styles.productImgStyle, transform: 'scale(1.2)' } : styles.productImgStyle}
                src={item?.line_items[0]?.image[0]}
                alt="Product Image"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
            ),
            sticky: "left"
        },
        {
            Header: "Title",
            accessor: (item: any) => (item?.title ? item?.title : null),
            sticky: "left"
        },
        {
            Header: "Price",
            accessor: (item: any) => (item?.price ? `${item?.price} ₹` : `${item?.line_items[0]?.mrp} ₹`),
            sticky: "left",
            Cell: ({ value }: any) => (
                <div className="price-cell" style={styles?.priceStyle}>
                    {value}
                </div>
            )
        },
        // {
        //     Header: "Vendor",
        //     accessor: (item: any) => (item?.vendor ? `${item?.vendor}` : null),

        //     sticky: "left"
        // },
        // {
        //     Header: "Publish Status",
        //     accessor: (item: any) => (
        //         item?.status === "active" ? (
        //             <span className="badge badge-success">Active</span>
        //         ) : (
        //             <span className="badge badge-secondary">Draft</span>
        //         )
        //     ),
        //     sticky: "left"
        // },
        {
            Header: "Action",
            accessor: (item: any) => {
                if (!item?.parent_id) return null;

                const isAdmin = decoded?.data?.isAdmin === "true";
                const canEdit = isAdmin || (product?.update && item?.parent_id);
                const canDelete = isAdmin || (product?.delete && item?.parent_id);
                return (
                    <div className="row">
                        <div className="col-md-12">
                            {canEdit && (
                                <Link to={`/addproduct/${item?.parent_id}`} className="btn btn-primary justify-content-start btn-circle">
                                    <i className={isAdmin ? "fa fa-edit" : "fas fa-edit"}></i>
                                </Link>
                            )}
                            {canDelete && (
                                <button
                                    className="btn btn-danger ml-2"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    onClick={() => setId(item?.parent_id)}
                                >
                                    <i className="fas fa-trash"></i>
                                </button>
                            )}
                        </div>
                    </div>
                );
            },
            sticky: "left",
        }
    ];

    console.log("productList", productList)
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 ">
                        <div className="card shadow ">
                            <div className="card-header">
                                <h6 className="m-0 font-weight-bold text-danger text-left">Products List</h6>
                                <div className="search mt-1">
                                    <div className="row m-0 d-flex">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div className="col-md-4">
                                                <label htmlFor="exampleInputEmail1">Search By Title | Status | Price</label>
                                                <input type="search" name="order_id" placeholder="Search" className="mr-2 form-control"
                                                    onChange={(event) => filterBySearch(event)} />
                                            </div>
                                            <div>
                                                <button className="btn btn-danger"
                                                    onClick={() => exportToCSV(SearchList)} disabled={!SearchList || SearchList.length === 0}>
                                                    Download Sheet
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    {
                                        loading ? <Loader /> : (
                                            <div className="table-responsive table-bordered ">
                                                {!loading ? <Paginated data={SearchList} columns={COLUMNS} /> : <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}><CircularProgress /></div>}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="modal fade" id="exampleModal" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            {/* <h5 className="modal-title text-center"></h5> */}
                                            <button type="button" ref={closeModel} className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <p>Are you sure want to delete !</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-danger" onClick={deleteProduct}>
                                                {
                                                    deleteLoading ? "Loading..." : "Delete"
                                                }
                                            </button>
                                            {
                                                deleteLoading ? null : (
                                                    <button type="button" id="closebtn" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default ProductList;