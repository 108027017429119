
import TableCell from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ImageAdd from '../../components/ImageAdd/ImageAdd';
import styles from "./Variant.module.css"



const smstyle = {
    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 800, bgcolor: 'background.paper', boxShadow: 24, borderRadius: "10px", p: 1, minHeight: 400, maxHeight: 600, overflow: "scroll", color: "black",
};
export default function CombinationTableRows({ productTypeNmae, iopen, handleImageOpen, handleimageClose, removeImageHandler, inputValues, handleChange, productImageHandler, product, tableData, index, combinations, productimageIndex, handlePrice, manDatoryAttr }) {

    const productType = productTypeNmae.toLowerCase();
    console.log("productType---------->", productType)
    console.log("combinations--------->", combinations)
    console.log("tableData--------->", tableData)
    return (
        <>
            <TableCell align="center">
            <select name="status" id="" className={`form-control ${styles.tablecellBox}`} value={tableData[index]?.status} onChange={(e) => handleChange(e, index, combinations)}>
                    <option value="" disabled selected>Select</option>
                    <option value="draft">Draft</option>
                    <option value="active">Active</option>
                   
                </select>
            </TableCell>
            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="mrp" value={tableData[index]?.mrp} onChange={(e) => handleChange(e, index, combinations)} />
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="selling_price" value={tableData[index]?.selling_price} onChange={(e) => handlePrice(e, index, combinations)} />
            </TableCell>

            <TableCell align='center'>
                <input type="text" className='form-control' placeholder='Tax Included Price' name=""
                    value={tableData[index]?.tax_included_price}
                    // onChange={(e) => handleChange(e, index, item)}
                    readOnly />
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="quantity" value={tableData[index]?.quantity} onChange={(e) => handleChange(e, index, combinations)} />
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="sku" value={tableData[index]?.sku} onChange={(e) => handleChange(e, index, combinations)} />
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="productid" value={tableData[index]?.productid} onChange={(e) => handleChange(e, index, combinations)} />
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} placeholder='' name="barcode" v value={tableData[index]?.barcode} onChange={(e) => handleChange(e, index, combinations)} />
            </TableCell>

            <TableCell align="center">
                <select name="uom" id="" className={`form-control ${styles.tablecellBox}`} value={tableData[index]?.uom} onChange={(e) => handleChange(e, index, combinations)}>
                    <option value="" disabled selected>Select</option>
                    <option value="unit">Unit</option>
                    <option value="dozen">Dozen</option>
                    <option value="gram">Gram</option>
                    <option value="kilogram">Kilogram</option>
                    <option value="tonne">Ton</option>
                    <option value="litre">Litre</option>
                    <option value="millilitre">Millilitre</option>
                </select>
            </TableCell>

            <TableCell align="center">
                <input type="text" className={`form-control ${styles.tablecellBox}`} name="weight" value={tableData[index]?.weight} onChange={(e) => handleChange(e, index, combinations)} />
            </TableCell>
            {
                manDatoryAttr?.map((item) => {
                    return <TableCell align="center">
                        <input type="text" className={`form-control ${styles.tablecellBox}`} name={item.mandatory_attribute} value={tableData[index]?.[item.mandatory_attribute]} onChange={(e) => handleChange(e, index, combinations)} />
                    </TableCell>

                })


            }

            {tableData[index]?.image?.length > 0 ? <TableCell align="center">
                <span className='badge badge-info p-2' onClick={() => { handleImageOpen(tableData[index], index) }} style={{ cursor: "pointer" }} >See Images</span>
            </TableCell> : <TableCell align="center">
                <input type="file" className={`form-control ${styles.tablecellBox}`} style={{ lineHeight: "1", width: "127px" }} accept="image/*" name="image" multiple onChange={(e) => productImageHandler(e, index)} />
            </TableCell>
            }


            <Modal
                open={iopen}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={smstyle} className="variantBox">
                    <CloseIcon style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} onClick={handleimageClose} />
                    <p className="" style={{ fontSize: "20px", fontWeight: "700" }}> Images</p>


                    <div>
                        <ImageAdd productImageHandler={productImageHandler} productData={productimageIndex[0]} imageIndex={productimageIndex[1]} removeImageHandler={removeImageHandler} />
                    </div>
                </Box>
            </Modal>

        </>

    )
}
