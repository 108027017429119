import Sidebar from "./sidebar";
import Navbar from "./navbar";
import Footer from "./footer";


import React, { ReactNode } from "react";

interface Props {
    children?: ReactNode
}
const bgStyle: React.CSSProperties = {
    // width: "82rem",
}

const ThemeMaker = ({ children }: Props) => {
    return (
        <>
            <div id="wrapper" style={{ justifyContent: "flex-end" }}>
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column" style={bgStyle}>
                    <div id="content">
                        <Navbar />
                        {children}
                        <Footer />
                    </div>
                </div>
            </div >
        </>
    )
}

export default ThemeMaker;