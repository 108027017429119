import AllRoutes from "./routes";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import React from "react";
const App = () => {
  return (
    <>
      <AllRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
